import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  progress: {
    position: 'absolute',
    color: theme.palette.primary.contrastText,
    left: 0,
    right: 0,
    bottom: 0,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  disabledProgress: {
    color: theme.palette.primary.main
  }
}));

export default useStyles;
