import React, { useState } from 'react';

import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';

import EnvironmentBox from '~/components/EnvironmentBox';

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

import styles from './styles';
import { AppBar } from './AppBar';
import ModalContact from '~/components/ModalContact';

export function AuthLayout({ children }) {
  const classes = styles();

  const [ open, setOpen ] = useState(false);

  function handleModal() {
    setOpen( !open );
  };

  return (
    <div className={classes.root}>
      <AppBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            {children}
          <EnvironmentBox />
        </Container>
      </main>

      <Fab
        color="primary"
        className={classes.button}
        variant="extended"
        onClick={() => setOpen( true )}
      >
        <QuestionAnswerIcon className={ classes.buttonIcon }/>

        Fale Conosco
      </Fab>

      <ModalContact open={ open } handleModal={ handleModal } />
    </div>
  );
}
