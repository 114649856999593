import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

import {
  useHistory,
} from 'react-router-dom';

const useStyles = makeStyles({
  depositContext: {
    fontSize: 14,
    marginBottom: 30,
  },
});

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#FFF',
  },
  barColorPrimary: {
    backgroundColor: '#DCDCDC',
  },
})(LinearProgress);

export default function Deposits({ loading, title, type, countPropostas, getReport }) {
  const classes = useStyles();

  const history = useHistory();

  const [ loadingReport, setLoadingReport ] = useState(false);

  async function handleLoadingReport() {
    setLoadingReport(true);

    await getReport();

    setLoadingReport(false);
  };

  return (
    <>
      <Typography style={{ color: '#FFF'}} component="span" variant="h6">{ title }</Typography>

      <div style={{ position: 'absolute', bottom: 15, right: 15 }}>
        { !loading ?
          <Typography component="span" variant="h4" style={{ fontWeight: 'bold', color: '#FFF' }}>
            { countPropostas }
          </Typography>
        : <CircularProgress size={ 30 } style={{ marginTop: 6, color: '#FFF' }}/>
        }
      </div>

      <div style={{ position: 'absolute', bottom: 5, left: 10, cursor: 'pointer', width: '94%'}}>
        {
          countPropostas > 0 ?
            !loadingReport ?
              <Link style={{ color: '#FFF'}} onClick={handleLoadingReport}
              >
                Saiba Mais
              </Link>
            : <ColorLinearProgress />
          : <></>
        }
      </div>
    </>
  );
}
