import React, { useEffect, useState } from 'react';

import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';

import sompo from './assets/head-sompo.png';
import mapfre from './assets/head-mapfre.png';
import porto from './assets/head-porto.png';
import liberty from './assets/head-liberty.png';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    paddingBottom: 10,
    paddingTop: 10,
    paddingHorizontal: 25,
  },

  titleView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    marginTop: 20,
    borderBottom: 3,
    borderBottomColor: '#777'
  },

  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#333',
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: 20,
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '90%',
    marginTop: 5,
  },

  dot: {
    backgroundColor: '#444',
    padding: 3,
    marginRight: 15,
    borderRadius: '50%',
    marginTop: 8,
  },

  text: {
    fontSize: 18,
    color: '#777',
  },

  lineAlert: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    borderBottom: 4,
    borderBottomColor: '#CCC'
  },

  alertMessage: {
    fontSize: 11,
    color: '#333'
  },
});

export default function DocumentPDF({ tipo, data }) {
  const [ pdfData, setPdfData ] = useState([]);

  function getHeaderImage( seguradora ) {
    switch( seguradora ) {
      case 'SOMPO':
        return sompo;
      case 'MAPFRE':
        return mapfre;
      case 'PORTO SEGURO':
        return porto;
      case 'LIBERTY SEGUROS':
        return liberty;
    }
  };

  function filterAssistencias( seguradora ) {
    return seguradora.assistencias = seguradora.planos[ tipo.id ].coberturasArr;
  };

  function filterPriceCobertura( seguradora ) {
    return seguradora.price = seguradora.planos[ tipo.id ].preco;
  };

  useEffect(() => {
    const pdf = [];

    data.map(( seguradora, index ) => {
      // if( !( seguradora.nome === 'SOMPO' && tipo.id === 2 ) && !( seguradora.nome === 'LIBERTY SEGUROS' && tipo.id === 2 ) && !( seguradora.nome === 'LIBERTY SEGUROS' && tipo.id === 3 )) {
        pdf.push({
          pkSeguradoras: seguradora.pkSeguradoras,
          nome: seguradora.nome,
          assistencias: filterAssistencias( seguradora ),
          price: filterPriceCobertura( seguradora ),
        });
      // }
    });

    setPdfData( pdf );
  }, [ tipo ]);

  return (
    <Document>
      {
        pdfData.map(( seguradora, index ) =>
          <Page key={ seguradora.pkSeguradoras } size="A4" style={styles.page}>
            <View style={ styles.top }>
              <Image source={() => getHeaderImage( seguradora.nome )} />

              <View style={ styles.titleView }>
                <Text style={ styles.title }>Assistência { tipo.label }: { seguradora.price > 0 ? Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( seguradora.price ) : 'Gratuito'}</Text>
              </View>

              <View style={ styles.list }>
                {
                  seguradora.assistencias
                    ? seguradora.assistencias.map( assistencia => (
                        <View key={ assistencia.id } style={ styles.row }>
                          <View style={ styles.dot } />

                          <Text style={ styles.text }>{ assistencia.label }</Text>
                        </View>
                      ))
                    : <></>
                }
              </View>
            </View>

            <View style={ styles.lineAlert }>
              <Text style={ styles.alertMessage }>Importante: para maiores informações, consulte as condições gerais da sua apólice.</Text>
            </View>
          </Page>
        )
      }
    </Document>
  );
};
