import React from 'react';

import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';

import logo from '../../assets/logo.png';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
    padding: 25,
  },

  header: {
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 10,
    borderBottom: 1,
    borderBottomColor: '#CCC',
  },

  title: {
  },

  filtros: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
    height: '70%',
    marginTop: 10
  },

  filtrosRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    height: '70%',
  },

  textFiltros: {
    fontSize: 10,
  },

  section: {
    marginTop: 20,
    width: '100%',
  },

  logo: {
    width: 70,
  },

  table: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'column',
  },

  tableRowHeader: {
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    borderBottom: .5,
  },

  tableLine: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderBottom: .3,
    borderBottomColor: '#CCC',
  },

  tableRow: {
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
  },

  tableCellHead: { fontSize: 11, width: '14%' },

  tableCellHeadVig: { fontSize: 11, width: '10%' },

  tableCellBody: { fontSize: 8, width: '14%' },

  tableCellBodyVig: { fontSize: 8, width: '10%' },

  tableRowFooter: {
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'right',
    width: '100%',
  },
});

export default function DocumentPDF({ filtros, propostas }) {
  const dataInicio = Intl.DateTimeFormat('pt-BR').format( filtros.start_date );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header }>
          <View>
            <Text style={ styles.title }>Propostas do dia</Text>

            <View style={ styles.filtros }>
              <Text style={ styles.textFiltros }>{ dataInicio }</Text>
            </View>
          </View>

          <Image src={ logo } style={ styles.logo } />
        </View>

        <View style={ styles.table }>
          <View style={ styles.tableRowHeader }>
            {/* <Text style={ styles.tableCellHead } align="left">Protocolo</Text> */}

            <Text style={ styles.tableCellHead } align="left">Usuário</Text>

            <Text style={ styles.tableCellHead } align="left">Proprietário</Text>

            <Text style={ styles.tableCellHead } align="left">Inquilino</Text>

            <Text style={ styles.tableCellHead } align="left">Seguradora</Text>

            <Text style={ styles.tableCellHeadVig } align="left">Vig. Início</Text>

            <Text style={ styles.tableCellHeadVig } align="left">Vig. Fim</Text>
          </View>

          {
            propostas.map( proposta => (
              <View key={ proposta.pkProposta } style={ styles.tableLine }>
                <View style={ styles.tableRow }>
                  {/* <Text style={ styles.tableCellBody } align="left">{ proposta.pkProposta }</Text> */}

                  <Text style={ styles.tableCellBody } align="left">{ proposta.nomeUsuario }</Text>

                  <Text style={ styles.tableCellBody } align="left">{ proposta.proprietarioNome }</Text>

                  <Text style={ styles.tableCellBody } align="left">{ proposta.inquilinoNome }</Text>

                  <Text style={ styles.tableCellBody } align="left">{ proposta.nomeSeguradora }</Text>

                  <Text style={ styles.tableCellBodyVig } align="left">{ proposta.vigenciaInicio }</Text>

                  <Text style={ styles.tableCellBodyVig } align="left">{ proposta.vigenciaFim }</Text>
                </View>
              </View>
            ))
          }
        </View>
      </Page>
    </Document>
  );
};
