import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import MaterialLink from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import { delay } from '~/utils/helpers';
import { LoadingButton } from '~/components';

import useStyles from './ForgotPassword.styles';

export function ForgotPassword() {
  const [sending, setSending] = useState(false);

  const classes = useStyles();

  async function handleForgotPassword() {
    setSending(true);

    await delay();

    setSending(false);
  }

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper}>
        <LoadingButton
          onClick={handleForgotPassword}
          className={classes.button}
          disabled={sending}
          loading={sending}
          variant="contained"
          color="primary"
          size="large"
        >
          Enviar e-mail de recuperação
        </LoadingButton>
        <MaterialLink to="/login" variant="body2" component={Link}>
          Fazer login
        </MaterialLink>
      </Paper>
    </Container>
  );
}
