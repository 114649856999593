import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: 500,
    height: 300,
  },

  bodyModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    height: '100%',
    padding: 10,
  },

  row: {
    fontSize: 16,
  },

  modalActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
  },

  toolbar: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    minHeight: 40
  },

  title: {
    flex: '1 1 100%',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.fontSize * 1.25
  },

  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function ModalContact({ open, handleModal }) {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={ handleModal }
      className={classes.modal}
    >
      <Paper className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6" id="usuarios">
            Fale Conosco
          </Typography>
        </Toolbar>

        <div className={ classes.bodyModal }>
          <span className={ classes.row }>Contato via email: <a href="mailto:administrativo@tdaseguros.com.br" target="_blank">administrativo@tdaseguros.com.br</a></span>
          <span className={ classes.row }>Telefone / Celular: (61) 3344-5262 / (61) 9 9988-6472</span>
          <span className={ classes.row }>Contato via WhatsApp: <a href="https://wa.me/5561999886472" target="_blank">(61) 9 9988-6472</a></span>

          <div className={ classes.modalActions }>
            <Button
              color="primary"
              variant="contained"
              onClick={ handleModal }
            >
              OK
            </Button>
          </div>
        </div>
      </Paper>
    </Modal>
  );
};
