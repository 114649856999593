export const coberturasBasicaKey = 'assistencia24HText';
export const coberturasIntermedKey = 'assistencia24HTextInter';
export const coberturasCompletaKey = 'assistencia24HTextVip';

export const precoComercialBasicaKey = 'vlAssistencia24HComercial';
export const precoComercialIntermedKey = 'vlAssistencia24HComercialInter';
export const precoComercialCompletaKey = 'vlAssistencia24HComercialVip';

export const precoResidencialBasicaKey = 'vlAssistencia24H';
export const precoResidencialIntermedKey = 'vlAssistencia24HInter';
export const precoResidencialCompletaKey = 'vlAssistencia24HVip';

export const NAO_CONTRATAR = '0';
export const BASICA = '1';
export const INTERMED = '2';
export const COMPLETA = '3';

export const tiposAssistencia = [
  {
    id: 1,
    value: BASICA,
    label: 'Básica',
    showAssistencia: true,
  },
  {
    id: 2,
    value: INTERMED,
    label: 'Intermediária',
    showAssistencia: true,
  },
  {
    id: 3,
    value: COMPLETA,
    label: 'Completa',
    showAssistencia: true,
  }
];

export const tiposAssistenciaBasica = [
  {
    id: 1,
    value: BASICA,
    label: 'Básica',
    showAssistencia: true,
  },
];
