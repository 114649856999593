import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';

import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import MoneyIcon from '@material-ui/icons/AttachMoney';

import {
  useHistory,
} from 'react-router-dom';

import AssignmentIcon from '@material-ui/icons/Assignment';

import { SIMULACAO_INITIAL, simulacaoSchema } from '~/utils/constants';
import { LoadingButton } from '~/components';

import { useStore } from '~/store';

import useStyles from './styles';
import { SimulacaoForm } from './SimulacaoForm';
import { ValoresAssistenciaModal } from './ValoresAssistencia';

export function Simulacao({ activeStep, setActiveStep }) {
  const { simulacao, alert } = useStore();

  const history = useHistory();

  const [sending, setSending] = useState(false);
  const [valoresAssisOpen, setValoresAssisOpen] = useState(false);

  const [ tipoAssistencia, setTipoAssistencia ] = useState({});

  function handleShowAssistencia( tipo ) {
    setTipoAssistencia( tipo );

    // setValoresAssisOpen( true );
  };

  const classes = useStyles();

  async function handleSubmit(formData) {
    setSending(true);

    formData.qtdParcelas = 1;

    formData.tipoAtividade = formData.tipoImovel === 'Residencial' ? '' : formData.tipoAtividade;
    formData.tipoOcupacao = formData.tipoImovel === 'Comercial' ? '' : formData.tipoOcupacao;

    const dataLocalStorage = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));

    try {
      const result = await simulacao.getSimulacao(formData);

      if (result.success) {
        localStorage.setItem('TDA_Seguros@Simulacao', JSON.stringify({ ...dataLocalStorage, ...formData }));

        setSending(false);
        setActiveStep(activeStep + 1);
      }
    } catch {
      alert.addToast({
        message: 'Erro inesperado ao realizar simulação, tente novamente'
      });
    } finally {
      setSending(false);
    }
  };

  useEffect(() => {
    const simulacao = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));
    const logged = localStorage.getItem('@TDA_token');

    if( simulacao ) {
      if( simulacao.seguradora !== undefined ) {
        if( simulacao.status === 'outside') {
          if( logged ) {
            setActiveStep(2);
          }
        }
      }
    }
  }, [ setActiveStep ]);

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={SIMULACAO_INITIAL}
      validationSchema={simulacaoSchema}
      validateOnMount
    >
      {formik => {
        return (
          <Box className={classes.box}>
            <SimulacaoForm setOpen={ handleShowAssistencia } formik={formik} />
            <Box className={classes.buttonsWrapper}>
              {/* <LoadingButton
                className={classes.button}
                onClick={() => setValoresAssisOpen(true)}
                variant="outlined"
                color="primary"
                endIcon={<MoneyIcon />}
                disabled={formik.values.tipoImovel === ''}
                size="large"
              >
                Coberturas e Valores Assistência
              </LoadingButton> */}
              <LoadingButton
                className={classes.button}
                onClick={() => handleSubmit(formik.values)}
                disabled={!formik.isValid || sending}
                loading={sending}
                variant="contained"
                color="primary"
                endIcon={<CheckIcon />}
                size="large"
              >
                Calcular
              </LoadingButton>
            </Box>
            {/* <ValoresAssistenciaModal
              open={valoresAssisOpen}
              tipoAssistencia={ tipoAssistencia }
              close={() => setValoresAssisOpen(false)}
              tipoImovel={formik.values.tipoImovel}
              assistencia={formik.values.assistencia}
            /> */}
          </Box>
        );
      }}
    </Formik>
  );
}
