import { useState, useCallback } from 'react';
import { format } from 'date-fns';

import api from '~/services/api';
import { transformSimulacao, toCurrency } from '~/utils/helpers';
import {
  tiposCobertura
} from '~/utils/constants';

export default function useSimulacao() {
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [seguroSelected, setSeguroSelected] = useState();
  const [simulacao, setSimulacao] = useState();

  const getSimulacao = async ({
    valorAluguel,
    assistencia24,
    tipoCobertura,
    tipoImovel,
    tipoOcupacao,
    tipoAtividade,
    objSimulacaoFront
  }) => {
    setFetching(true);

    const dadosSimulacao = {
      valorAluguel,
      assistencia24H: assistencia24,
      tipoCobertura,
      imovelTipoImovel: tipoImovel,
      imovelTipoOcupacao: tipoOcupacao,
      codigoAtividade: tipoAtividade,
      objSimulacaoFront,
    };

    try {
      const { data: simulacaoData } = localStorage.getItem('@TDA_token')
        ? await api.getSimulacao(dadosSimulacao)
        : await api.getSimulacaoExterna(dadosSimulacao)

      setSimulacao(dadosSimulacao);

      const transformedData = transformSimulacao({ data: simulacaoData });

      setData(transformedData);

      return { success: true };
    } catch {
      return { success: false };
    } finally {
      setFetching(false);
    }
  };

  const confirmarSeguro = useCallback(
    async ({ confirmFormData }) => {
      try {
        const { data } = await api.enviarPropostaSeguro({
          imovelTipoImovel: simulacao.imovelTipoImovel,
          imovelTipoOcupacao: simulacao.imovelTipoOcupacao,
          imovelCodigoAtividade: simulacao.codigoAtividade,
          valorAluguel: simulacao.valorAluguel,
          assistencia24H: simulacao.assistencia24H,
          cobertura: getTipoCobertura( simulacao.tipoCobertura ),
          seguradora: seguroSelected.id,
          qtdParcelas: confirmFormData.qtdParcelas,
          valorSegurado: toCurrency( seguroSelected.coberturaIncendioRaioExplosao ),
          valorPagamentoAluguel: toCurrency(
            seguroSelected.coberturaPerdaPagamentoAluguel
          ),
          valorCoberturaResponsabilidadeCivil: toCurrency(
            seguroSelected.coberturaResponsabilidadeCivil
          ),
          valorCoberturaVendavalGranizo: toCurrency(
            seguroSelected.coberturaQuebraVidros
          ),
          valorCoberturaDanosEletricos: toCurrency(
            seguroSelected.coberturaDanosEletricos
          ),
          premioLiquido: toCurrency(seguroSelected.valorPremioLiquidoTotal),
          premioTotal: toCurrency(seguroSelected.valorPremioTotal),
          proprietarioNome: confirmFormData.proprietarioNome,
          proprietarioTipo: confirmFormData.proprietarioTipo,
          proprietarioRegistro: confirmFormData.proprietarioRegistro,
          inquilinoNome: confirmFormData.inquilinoNome,
          inquilinoTipo: confirmFormData.inquilinoTipo,
          inquilinoRegistro: confirmFormData.inquilinoRegistro,
          vigenciaInicio: format(confirmFormData.vigenciaInicio, 'dd/MM/yyyy'),
          vigenciaFim: format(confirmFormData.vigenciaFim, 'dd/MM/yyyy'),
          dataProposta: confirmFormData.dataProposta,
          localCep: confirmFormData.localCep,
          localEndereco: confirmFormData.localEndereco,
          localNumero: confirmFormData.localNumero,
          localComplemento: confirmFormData.localComplemento,
          localBairro: confirmFormData.localBairro,
          localCidade: confirmFormData.localCidade,
          localEstado: confirmFormData.localEstado,
          gerarBoleto: confirmFormData.gerarBoleto,
          diasAteVencimentoBoleto: 5
        });
        return { data, success: true };
      } catch (err) {
        return { success: false };
      }
    },
    [seguroSelected, simulacao]
  );

  const selectSeguro = useCallback(({ seguro }) => {
    setSeguroSelected(seguro);
  }, []);

  function getTipoCobertura( tipo_id ) {
    return tiposCobertura.filter( tipo => tipo.value === tipo_id )[ 0 ].value_bd;
  };

  function textAssistencia( value ) {
    switch( value ) {
      case '1': return 'Básica';
      case '2': return 'Intermediária';
      case '3': return 'Completa';
      default: return 'Não Contratado';
    }
  };

  return {
    data,
    fetching,
    getSimulacao,
    seguroSelected,
    selectSeguro,
    confirmarSeguro,
  };
}
