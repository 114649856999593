import React, { useEffect } from 'react';
import { useTransition, animated } from 'react-spring';

import Box from '@material-ui/core/Box';
import MaterialAlert from '@material-ui/lab/Alert';

import { useStore } from '~/store';

import useStyles from './styles';

export function Alert() {
  const { alert } = useStore();

  const classes = useStyles();

  const toastsWithTransitions = useTransition(alert.toasts, toast => toast.id, {
    from: { right: '-120%', opacity: 0 },
    enter: { right: '0%', opacity: 1 },
    leave: { right: '-120%', opacity: 0 }
  });

  return (
    <Box className={classes.container}>
      {toastsWithTransitions.map(({ item, key, props }) => (
        <Toast key={key} toast={item} style={props} />
      ))}
    </Box>
  );
}

export function Toast({ toast, style }) {
  const { alert } = useStore();

  const classes = useStyles();

  useEffect(() => {
    const timer = setTimeout(() => {
      alert.removeToast(toast.id);
    }, 3500);

    return () => {
      clearTimeout(timer);
    };
  }, [toast, alert]);

  return (
    <animated.div style={style}>
      <MaterialAlert
        className={classes.toast}
        onClose={() => {
          alert.removeToast(toast.id);
        }}
        severity={toast.type || 'error'}
        closeText="Fechar"
      >
        {toast.message}
      </MaterialAlert>
    </animated.div>
  );
}
