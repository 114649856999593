import React, { useState, useEffect } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

import { object, string } from 'yup';

import { Form, Formik } from 'formik';
import { FormikCurrency } from '~/components';

import { LoadingButton } from '~/components';

import Modal from '@material-ui/core/Modal';

import EditIcon from '@material-ui/icons/Edit';

import { TextField } from '~/components';
// import { coberturas } from '~/utils/constants';
import { toCurrency } from '~/utils/helpers';

import useStyles from './styles';

import { useStore } from '~/store';

import {
  incendio,
  eletrico,
  aluguel,
  vendaval,
  civil,
} from '../../../../utils/constants/tooltipText';
import api from '~/services/api';

export function ResultadoSimulacaoTable({ simulacao, onSelect }) {
  const [selected, setSelected] = useState();

  const [ dataHook, setDataHook ] = useState([]);
  const [premioTotal, setPremioTotal] = useState(0);

  const { alert } = useStore();

  const [ open, setOpen ] = useState({ open: false, cobertura: '', title: ''});

  function handleClose() {
    setOpen({ open: false, cobertura: ''});
  };

  useEffect(() => {
    setDataHook( simulacao.data );
  }, [ simulacao.data, dataHook ]);

  useEffect(() => {
    let message = '';

    if( simulacao.data ) {
      simulacao.data.forEach(item => {
        if( item.objLimitesDanosEletricos ) {
          if( item.objLimitesDanosEletricos.mensagem ) {
            message = item.objLimitesDanosEletricos.mensagem;
          }
        }

        if( item.objLimitesPagamentoAluguel ) {
          if( item.objLimitesPagamentoAluguel.mensagem ) {
            message = item.objLimitesPagamentoAluguel.mensagem;
          }
        }

        if( item.objLimitesQuebraVidros ) {
          if( item.objLimitesQuebraVidros.mensagem ) {
            message = item.objLimitesQuebraVidros.mensagem;
          }
        }

        if( item.objLimitesResponsabilidadeCivil ) {
          if( item.objLimitesResponsabilidadeCivil.mensagem ) {
            message = item.objLimitesResponsabilidadeCivil.mensagem;
          }
        }
      });
    }

    if( message ) {
      alert.addToast({
        type: 'warning',
        message: message,
      });
    }
  }, [ simulacao.data ]);

  useEffect(() => {
    localStorage.setItem('TDA_Seguros@SimulacaoStorage', JSON.stringify( simulacao.data ));

    let arrayDeletados = [];

    for( let i = 0; i < simulacao.data.length; i ++ ) {
      arrayDeletados.push({});
    }

    localStorage.setItem('TDA_Seguros@SimulacaoValoresDeletados', JSON.stringify( arrayDeletados ));
  }, []);

  const [ enableIncendio, setEnableIncendio ] = useState(true);
  const [ enablePerda, setEnablePerda ] = useState(true);
  const [ enableCivil, setEnableCivil ] = useState(true);
  const [ enableVendaval, setEnableVendaval ] = useState(true);
  const [ enableEletricos, setEnableEletricos ] = useState(true);

  const [ modalAlert, setModalAlert ] = useState( false );

  const [ loading, setLoading ] = useState({load: false, cobertura: ''});

  const classes = useStyles();

  async function verify( seguradora ) {
    const { data } = await api.verifyCredentials( seguradora );

    setModalAlert( data.alert );
  };

  function handleSelect(e) {
    verify( e.target.value );

    setSelected(e.target.value);
  }

  const coberturasArray = [
    {
      selectable: false,
      getEnableCobertura: enableIncendio,
      setEnableCobertura: event => {
        setEnableIncendio( event.target.checked );

        setLoading({ load: true, cobertura: 'incendio_raio_explosao'});
      },
      editable: true,
      editValue: cobertura => setOpen({ open: true, cobertura, title: 'Incêndio / Raio / Explosão'}),
      value: 'incendio_raio_explosao',
      label: 'Incêndio / Raio / Explosão',
      responseKey: 'coberturaIncendioRaioExplosao',
      tooltipText: incendio
    },
    {
      selectable: false,
      getEnableCobertura: enablePerda,
      setEnableCobertura: event => {
        setEnablePerda( event.target.checked );

        setLoading({ load: true, cobertura: 'perda_pagamento_aluguel'});
      },
      editable: false,
      editValue: cobertura => setOpen({ open: true, cobertura, title: 'Perda/Pagamento Aluguel'}),
      value: 'perda_pagamento_aluguel',
      label: 'Perda/Pagamento Aluguel',
      responseKey: 'coberturaPerdaPagamentoAluguel',
      tooltipText: aluguel
    },
    {
      selectable: true,
      getEnableCobertura: enableCivil,
      setEnableCobertura: event => {
        setEnableCivil( event.target.checked );

        setLoading({ load: true, cobertura: 'responsabilidade_civil'});
      },
      editable: true,
      editValue: cobertura => setOpen({ open: true, cobertura, title: 'Responsabilidade Civil'}),
      value: 'responsabilidade_civil',
      label: 'Responsabilidade Civil',
      responseKey: 'coberturaResponsabilidadeCivil',
      tooltipText: civil
    },
    {
      selectable: true,
      getEnableCobertura: enableVendaval,
      setEnableCobertura: event => {
        setEnableVendaval( event.target.checked );

        setLoading({ load: true, cobertura: 'quebra_vidros'});
      },
      editable: true,
      editValue: cobertura => setOpen({ open: true, cobertura, title: 'Vendaval / Granizo'}),
      value: 'quebra_vidros',
      label: 'Vendaval / Granizo',
      responseKey: 'coberturaQuebraVidros',
      tooltipText: vendaval
    },
    {
      selectable: true,
      getEnableCobertura: enableEletricos,
      setEnableCobertura: event => {
        setEnableEletricos( event.target.checked );

        setLoading({ load: true, cobertura: 'danos_eletricos'});
      },
      editable: true,
      editValue: cobertura => setOpen({ open: true, cobertura, title: 'Danos Elétricos'}),
      value: 'danos_eletricos',
      label: 'Danos Elétricos',
      responseKey: 'coberturaDanosEletricos',
      tooltipText: eletrico
    }
  ];

  async function editCoberturaSimulacao( dados ) {
    const openAux = open;
    setOpen({ open: false, cobertura: openAux.cobertura });

    try {
      const simulacaoStorage = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));

      if( open.cobertura === 'incendio_raio_explosao') {
        setLoading({ load: true, cobertura: open.cobertura });

        let objSimulacaoFront = dataHook.map(( dado, index ) => {
          let newDado = dado;

          newDado.coberturaIncendioRaioExplosao = parseFloat( dados.valor );

          return newDado;
        });

        await simulacao.getSimulacao({ ...simulacaoStorage, objSimulacaoFront });

        setOpen({ open: false, cobertura: ''});
      }

      if( open.cobertura === 'perda_pagamento_aluguel') {
        setLoading({ load: true, cobertura: open.cobertura });

        let objSimulacaoFront = dataHook.map(( dado, index ) => {
          let newDado = dado;

          newDado.coberturaPerdaPagamentoAluguel = parseFloat( dados.valor );

          return newDado;
        });

        await simulacao.getSimulacao({ ...simulacaoStorage, objSimulacaoFront });

        setOpen({ open: false, cobertura: ''});
      }

      if( open.cobertura === 'responsabilidade_civil') {
        setLoading({ load: true, cobertura: open.cobertura });

        let objSimulacaoFront = dataHook.map(( dado, index ) => {
          let newDado = dado;

          newDado.coberturaResponsabilidadeCivil = parseFloat( dados.valor );

          return newDado;
        });

        await simulacao.getSimulacao({ ...simulacaoStorage, objSimulacaoFront });

        setOpen({ open: false, cobertura: ''});
      }

      if( open.cobertura === 'quebra_vidros') {
        setLoading({ load: true, cobertura: open.cobertura });

        let objSimulacaoFront = dataHook.map(( dado, index ) => {
          let newDado = dado;

          newDado.coberturaQuebraVidros = parseFloat( dados.valor );

          return newDado;
        });

        await simulacao.getSimulacao({ ...simulacaoStorage, objSimulacaoFront });

        setOpen({ open: false, cobertura: ''});
      }

      if( open.cobertura === 'danos_eletricos') {
        setLoading({ load: true, cobertura: open.cobertura });

        let objSimulacaoFront = dataHook.map(( dado, index ) => {
          let newDado = dado;

          newDado.coberturaDanosEletricos = parseFloat( dados.valor );

          return newDado;
        });

        await simulacao.getSimulacao({ ...simulacaoStorage, objSimulacaoFront });

        setOpen({ open: false, cobertura: ''});
      }

      setDataHook( simulacao.data );

      setLoading({ load: false, cobertura: ''});
    } catch (error) {
      //
    }
  };

  async function getSimulacao() {
    try {
      const simulacaoStorage = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));

      let simulacaoValoresDeletados = JSON.parse( localStorage.getItem('TDA_Seguros@SimulacaoValoresDeletados'));

      if( !enableCivil && loading.cobertura === 'responsabilidade_civil') {
        let objSimulacaoFront = dataHook.map(( dado, index ) => {
          let newDado = dado;

          simulacaoValoresDeletados[index].coberturaResponsabilidadeCivil = newDado.coberturasExibir.responsabilidade_civil;
          newDado.coberturaResponsabilidadeCivil = 0;

          return newDado;
        });

        await simulacao.getSimulacao({ ...simulacaoStorage, objSimulacaoFront });
      } else {
        if( loading.cobertura === 'responsabilidade_civil') {
          let objSimulacaoFront = dataHook.map(( dado, index ) => {
          let newDado = dado;

          newDado.coberturaResponsabilidadeCivil = simulacaoValoresDeletados[index].coberturaResponsabilidadeCivil;
          newDado.objLimitesResponsabilidadeCivil.valorDigitadoPeloUsuario = simulacaoValoresDeletados[index].coberturaResponsabilidadeCivil;

          return newDado;
        });

        await simulacao.getSimulacao({ ...simulacaoStorage, objSimulacaoFront });
        }
      }

      if( !enableVendaval && loading.cobertura === 'quebra_vidros') {
        let objSimulacaoFront = dataHook.map(( dado, index ) => {
          let newDado = dado;

          simulacaoValoresDeletados[index].coberturaQuebraVidros = newDado.coberturasExibir.quebra_vidros;
          newDado.coberturaQuebraVidros = 0;

          return newDado;
        });

        await simulacao.getSimulacao({ ...simulacaoStorage, objSimulacaoFront });
      } else {
        if( loading.cobertura === 'quebra_vidros') {
          let objSimulacaoFront = dataHook.map(( dado, index ) => {
          let newDado = dado;

          newDado.coberturaQuebraVidros = simulacaoValoresDeletados[index].coberturaQuebraVidros;
          newDado.objLimitesQuebraVidros.valorDigitadoPeloUsuario = simulacaoValoresDeletados[index].coberturaQuebraVidros;

          return newDado;
        });

        await simulacao.getSimulacao({ ...simulacaoStorage, objSimulacaoFront });
        }
      }

      if( !enableEletricos && loading.cobertura === 'danos_eletricos') {
        let objSimulacaoFront = dataHook.map(( dado, index ) => {
          let newDado = dado;

          simulacaoValoresDeletados[index].coberturaDanosEletricos = newDado.coberturasExibir.danos_eletricos;
          newDado.coberturaDanosEletricos = 0;

          return newDado;
        });

        await simulacao.getSimulacao({ ...simulacaoStorage, objSimulacaoFront });
      } else {
        if( loading.cobertura === 'danos_eletricos') {
          let objSimulacaoFront = dataHook.map(( dado, index ) => {
          let newDado = dado;

          newDado.coberturaDanosEletricos = simulacaoValoresDeletados[index].coberturaDanosEletricos;
          newDado.objLimitesDanosEletricos.valorDigitadoPeloUsuario = simulacaoValoresDeletados[index].coberturaDanosEletricos;

          return newDado;
        });

        await simulacao.getSimulacao({ ...simulacaoStorage, objSimulacaoFront });
        }
      }

      setDataHook( simulacao.data );

      setLoading({ load: false, cobertura: ''});

      localStorage.setItem('TDA_Seguros@SimulacaoValoresDeletados', JSON.stringify( simulacaoValoresDeletados ));
    } catch( err ) {
      console.log( err );
    }
  };

  function convertMoney( value ) {
    if(value) {
      const valueArray = value?.toString().split('.');

      if( valueArray.length > 1 ) {
        const cents = `${valueArray[1].substr(0, 2)}0`;
  
        return `R$ ${ valueArray[0]},${ cents.substr(0, 2)}`;
      } else {
        return `R$ ${ valueArray[0]},00`;
      }
    }
  };

  useEffect(() => {
    getSimulacao();
  }, [ enableIncendio, enablePerda, enableCivil, enableVendaval, enableEletricos ]);

  useEffect(() => {
    dataHook.map( dado => {
      const simulacao = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));

      simulacao.qtdParcelas = dado.premioTotalParcelas[premioTotal]?.number;

      localStorage.setItem('TDA_Seguros@Simulacao', JSON.stringify( simulacao ));
    });
  }, [ premioTotal ]);

  useEffect(() => {
    if (selected) {
      onSelect(dataHook.find(dado => dado.id === selected));
    } else {
      onSelect(null);
    }
  }, [selected, dataHook, onSelect]);

  useEffect(() => {
    if( localStorage.getItem('TDA_Seguros@Simulacao')) {
      const {
        seguradora,
      } = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));

      setSelected( seguradora );
    }
  }, []);

  return (
    <>
      <TableContainer>
        <Table size="small" aria-label="resultados simulação">
          <TableHead>
            <TableRow>
              <TableCell className={classes.coberturaHeader}>
                Ações
              </TableCell>

              <TableCell className={classes.coberturaHeader}>
                Coberturas
              </TableCell>
              {dataHook.map(dado => (
                <TableCell
                  className={classes.avatarCell}
                  key={dado.id}
                  align="center"
                >
                  <Avatar
                    className={classes.avatar}
                    src={dado.seguradora.imagem}
                    alt={dado.seguradora.nome}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {coberturasArray.map(cobertura => (
              <TableRow key={cobertura.value}>
                <TableCell align="center">
                  {
                    cobertura.selectable ?
                      <Checkbox
                        color="primary"
                        checked={ cobertura.getEnableCobertura }
                        onChange={ cobertura.setEnableCobertura }
                        disabled={ loading.load }
                      />
                    : <></>
                  }{
                    cobertura.editable ?
                      <IconButton color={ loading.load || !cobertura.getEnableCobertura ? "default" : "primary" } onClick={() => cobertura.editValue( cobertura.value )} disabled={ loading.load || !cobertura.getEnableCobertura }>
                        <EditIcon />
                      </IconButton>
                    : <></>
                  }
                </TableCell>

                <TableCell
                  className={classes.cobertura}
                  component="th"
                  scope="row"
                >
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    {cobertura.label}

                    <Tooltip title={<span style={{ fontSize: 14, fontWeight: 'normal' }}>{ cobertura.tooltipText }</span>} placement="right">
                      <HelpOutlineIcon color="primary" fontSize="small" style={{ cursor: 'pointer' }}/>
                    </Tooltip>
                  </div>
                </TableCell>
                {dataHook.map(dado => (
                  <TableCell style={ dado.coberturasExibir[cobertura.value] === 0 ? { color: '#FF0000', opacity: .6 }: {}} key={dado.id} align="center">
                    {
                      loading.load ?
                        <Skeleton animation="wave" />
                      :
                        dado.coberturasExibir[cobertura.value] === 0 ?
                          'SEM COBERTURA'
                        :
                          toCurrency(dado.coberturasExibir[cobertura.value])
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell />
              <TableCell>PRÊMIO LÍQUIDO</TableCell>
              {dataHook.map(dado => (
                <TableCell key={dado.id} align="center">
                  {
                      loading.load ?
                        <Skeleton animation="wave" />
                      :
                        convertMoney( dado.premioLiquido )
                  }
                </TableCell>
              ))}
            </TableRow>
            <TableRow style={{ backgroundColor: '#bed2f1'}}>
              <TableCell />
              <TableCell variant="head">PRÊMIO TOTAL</TableCell>
              {dataHook.map(dado => (
                <TableCell key={dado.id} align="center">
                  {
                      loading.load ?
                        <Skeleton animation="wave" />
                      :
                      <TextField
                        value={dado.premioTotalParcelas[premioTotal]?.number}
                        name="qtdParcelas"
                        onChange={e => {
                          let i = dado.premioTotalParcelas.findIndex(( item, index ) => {
                            if( item.number === e.target.value ) {
                              return true
                            }

                            return false
                          })
                          setPremioTotal( i );
                        }}
                        select
                      >
                        {dado.premioTotalParcelas.map(parcela => (
                          <MenuItem key={parcela.value} value={parcela.number}>
                            {parcela.label}
                          </MenuItem>
                        ))}
                      </TextField>
                  }
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell variant="head">SELECIONAR SEGURADORA</TableCell>
              {dataHook.map(dado => (
                <TableCell key={dado.id} align="center">
                  <Radio
                    checked={selected === dado.id}
                    onChange={handleSelect}
                    value={dado.id}
                    name="selecionarSeguradora"
                    inputProps={{ 'aria-label': 'selecionar-seguradora' }}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Modal
        className={ classes.modal }
        open={open.open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={ classes.paperModal }>
          <Typography variant="h6">
            Cobertura: { open.title }
          </Typography>

          <Formik
            onSubmit={( dados ) => editCoberturaSimulacao( dados )}
            initialValues={{ valor: '' }}
            validationSchema={object().shape({
              valor: string()
                .required('Preencha o campo valor"')

                .test('validarValor', function ( value ) {
                  const { path, createError } = this;
                  if( parseInt(value) <= 0) {
                    return createError({ path, message: 'Valor Inválido!' });
                  }

                  return true;
                }),
            })}
          >
            <Form className={classes.form}>
              <FormikCurrency style={{ marginTop: 20 }} name="valor" label="Valor Cobertura"/>

              <div style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                width: '100%',
              }}>
                <LoadingButton
                  className={classes.button}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Salvar
                </LoadingButton>

                <LoadingButton
                  className={classes.button}
                  onClick={() => setOpen({ open: false, cobertura: '', title: ''})}
                  color="primary"
                  size="large"
                >
                  Cancelar
                </LoadingButton>
              </div>
            </Form>
          </Formik>
        </div>
      </Modal>

      <Modal
        className={ classes.modal }
        open={ modalAlert }
        onClose={handleClose}
      >
        <Paper className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title} variant="h6">
              Importante!
            </Typography>
          </Toolbar>
          <div className={ classes.bodyModal }>
            <span className={ classes.message }>Você pode prosseguir com a contratação, mas precisa efetivar o credenciamento da Imobiliária com esta seguradora. Entraremos em contato com você logo após o envio da Proposta.</span>

            <Button
              onClick={() => setModalAlert( false )}
              variant="contained"
              color="primary"
              size="large"
            >
              OK
            </Button>
          </div>
        </Paper>
      </Modal>
    </>
  );
}

// function ResultadoSimulacaoTableFooter({ seguradora }) {
//   const [premioTotal, setPremioTotal] = useState(0);

//   return (

//   );
// }
