import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
    overflow: 'hidden'
  },

  actionsCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  progressContainer: {
    position: 'absolute',
    alignSelf: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1.5),
    borderRadius: '50%'
  },
  toolbar: ({ sm }) =>
    !sm && {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1)
    },
  selectRoot: ({ sm }) =>
    !sm && {
      margin: 0
    },
  actions: ({ sm }) =>
    !sm && {
      margin: 0,
      alignSelf: 'stretch',
      display: 'flex',
      justifyContent: 'space-between'
    },

    table: {
      padding: theme.spacing(3)
    }
}));

export default Styles;
