import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

import ListItem from '@material-ui/core/ListItem'
import Collapse from '@material-ui/core/Collapse'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import { appRoutes } from '~/routes/appRoutes'

import useStyles from './DrawerItens.styles'

function RouteItem ({ route, nested, onSelectItem }) {
  const { Icon } = route

  const history = useHistory()

  const classes = useStyles()

  const listItemClass = clsx({
    [classes.nested]: nested
  })

  function handleNavigate () {
    onSelectItem()
    history.push(route.path)
  }

  return (
    <ListItem className={listItemClass} button onClick={handleNavigate}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={route.label} />
    </ListItem>
  )
}

function NestedRouteItem ({ route, onSelectItem }) {
  const [open, setOpen] = useState(false)

  const { Icon } = route

  return (
    <>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={route.label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {route.nested.map(nestedRoute => (
            <RouteItem
              key={nestedRoute.path}
              route={nestedRoute}
              nested
              onSelectItem={onSelectItem}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}

export function DrawerItens ({ onSelectItem }) {
  return (
    <List>
      {appRoutes.map(route =>
        route.nested ? (
          <NestedRouteItem
            key={route.path}
            route={route}
            onSelectItem={onSelectItem}
          />
        ) : (
          <RouteItem
            key={route.path}
            route={route}
            onSelectItem={onSelectItem}
          />
        )
      )}
    </List>
  )
}
