import { useState, useCallback, useMemo } from 'react';
import { v4 } from 'uuid';

export default function useAuth() {
  const [toasts, setToasts] = useState([]);

  const removeToast = useCallback(id => {
    setToasts(oldAlerts => oldAlerts.filter(toast => toast.id !== id));
  }, []);

  const addToast = useCallback(({ message, type }) => {
    const id = v4();

    setToasts(oldAlerts => [...oldAlerts, { id, message, type }]);
  }, []);

  return useMemo(
    () => ({
      addToast,
      removeToast,
      toasts
    }),
    [toasts, addToast, removeToast]
  );
}
