import React, { useState } from 'react';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import useStyles from './styles';
import { Simulacao } from '../Incendio/Simulacao';
import { ResultadoSimulacao } from '../Incendio/ResultadoSimulacao';
import { Confirmacao } from '../Incendio/Confirmacao';
import { Cadastro } from './Cadastro';

const steps = [
  {
    id: 1,
    label: 'Simulação Seguro Incêndio',
    content: Simulacao
  },
  {
    id: 2,
    label: 'Resultado Simulação',
    content: ResultadoSimulacao
  },
  {
    id: 3,
    label: 'Confirmação',
    content: Confirmacao
  },
  {
    id: 4,
    label: 'Cadastro da Imobiliária',
    content: Cadastro
  }
];

export function PublicIncendio() {
  const [activeStep, setActiveStep] = useState(0);

  const classes = useStyles();

  function handleBack(index) {
    if (activeStep > index) {
      setActiveStep(index);
    }
  }

  function handleNext() {
    if (activeStep + 1 < steps.length) {
      setActiveStep(activeStep + 1);
    }
  }

  function renderContent() {
    const Content = steps[activeStep].content;

    return (
      <Content
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleNext={handleNext}
      />
    );
  }

  return (
    <Container className={classes.root} maxWidth="lg">
      <Stepper
        elevation={1}
        className={classes.stepper}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((step, index) => (
          <Step
            key={step.id}
            completed={index < activeStep}
            onClick={() => handleBack(index)}
            className={classes.step}
          >
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper className={classes.contentContainer}>{renderContent()}</Paper>
    </Container>
  );
}
