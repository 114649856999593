import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Paper from '@material-ui/core/Paper';

import ModalProposta from '~/components/ModalProposta';

import useStyles from './styles';
import { Simulacao } from './Simulacao';
import { Confirmacao } from './Confirmacao';
import { ResultadoSimulacao } from './ResultadoSimulacao';

const steps = [
  {
    id: 1,
    label: 'Simulação Seguro Incêndio',
    content: Simulacao
  },
  {
    id: 2,
    label: 'Resultado Simulação',
    content: ResultadoSimulacao
  },
  {
    id: 3,
    label: 'Confirmação',
    content: Confirmacao
  }
];

export function IncendioStepper() {
  const location = useLocation();

  const confirmar = location.state?.confirmar;
  const [activeStep, setActiveStep] = useState(confirmar ? 2 : 0);

  const [ proposta, setProposta ] = useState( null );

  const [ open, setOpen ] = useState(false);

  const classes = useStyles();

  function handleBack(index) {
    if (activeStep > index) {
      setActiveStep(index);
    }
  }

  function handleNext() {
    if (activeStep + 1 < steps.length) {
      setActiveStep(activeStep + 1);
    }
  }

  function renderContent() {
    const Content = steps[activeStep].content;

    return (
      <Content
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleNext={handleNext}
        setProposta={ setProposta }
        setOpen={ setOpen }
      />
    );
  }

  return (
    <div className={classes.root}>
      <Stepper
        elevation={1}
        className={classes.stepper}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((step, index) => (
          <Step
            key={step.id}
            completed={index < activeStep}
            onClick={() => handleBack(index)}
            className={classes.step}
          >
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper className={classes.contentContainer}>{renderContent()}</Paper>

      <ModalProposta data={ proposta } setOpen={ setOpen } open={ open } />
    </div>
  );
}
