import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

export function SwitchRoutes(routes) {
  return (
    <Switch>
      {routes.map((route, i) => {
        const { Component } = route;
        return (
          <Route key={i} exact={route.exact} path={route.path}>
            <Component />
          </Route>
        );
      })}
      <Redirect to={routes[0].path} />
    </Switch>
  );
}
