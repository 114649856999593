import React, { useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'

import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { authRoutes } from '~/routes/authRoutes'

import { ConfirmWrapper } from '~/components/ConfirmWrapper';

import useStyles from './Navigation.styles'

function RouteItem ({ route }) {
  const history = useHistory()

  const classes = useStyles()

  const match = useRouteMatch(route.path)

  const [ path, setPath ] = useState(window.location.pathname);

  function handleNavigate() {
    setPath(route.path);

    localStorage.clear();

    history.push(route.path)
  }

  return (
    window.location.pathname === '/simulacao-incendio'
      ?   <>
            <ListItem
              className={classes.item}
              button
              onClick={handleNavigate}
              selected={!!match}
              classes={ classes.selected }
            >
              <ListItemText primary="Fazer Login" />
            </ListItem>

            <ConfirmWrapper
              action="Sair"
              message="Tem certeza que deseja sair?"
              onConfirm={() => {
                localStorage.clear();

                window.location.href = 'https://www.google.com.br';
              }}
            >
              <IconButton color="inherit">
                <ExitToAppIcon />
              </IconButton>
            </ConfirmWrapper>
          </>
      : <></>
  )
}

export function Navigation () {
  const classes = useStyles()

  return (
    <List className={classes.list}>
      {authRoutes.map(
        route => !route.hide && <RouteItem key={route.path} route={route} />
      )}
    </List>
  )
}
