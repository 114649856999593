import React, { useState, useEffect, useCallback } from 'react';
import { Form, Formik } from 'formik';
import clsx from 'clsx';
import { addYears, startOfMonth, endOfMonth } from 'date-fns';

import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';

import { Box, Typography } from '@material-ui/core';
import { LoadingButton, FormikDate } from '~/components';
import { useStore } from '~/store';
import api from '~/services/api';
import { situacaoOptions } from '~/utils/constants';

import useStyles from './styles';

const INITIAL_VALUES = {
  seguradora: 'TODAS',
  start_date: new Date(),
  end_date: addYears(new Date(), 1),
  situacao: situacaoOptions[0].value
};

export function PropostaForm({ className, ...rest }) {
  const acaoStorage = JSON.parse( localStorage.getItem('TDA_SEGUROS@ACAO'));
  const { alert, propostas } = useStore();

  const [seguradoras, setSeguradoras] = useState([]);
  const [fetchingPropostas, setFetchingPropostas] = useState(false);
  const [fetchingSeguradoras, setFetchingSeguradoras] = useState(false);

  const classes = useStyles();

  const formClassName = clsx([classes.form, className]);

  const getSeguradoras = useCallback(async () => {
    setFetchingSeguradoras(true);

    try {
      const { data } = await api.getSeguradoras();

      setSeguradoras(data);
    } catch {
      alert.addToast({ message: 'Erro ao buscar lista de seguradoras' });
    } finally {
      setFetchingSeguradoras(false);
    }
  }, [alert]);

  useEffect(() => {
    getSeguradoras();
  }, [getSeguradoras]);

  async function handleSubmit(data) {
    localStorage.removeItem('TDA_SEGUROS@ACAO');

    setFetchingPropostas(true);
    const dateStart = new Date( data.start_date );
    const dateEnd = new Date( data.end_date );

    let filtrosRelatorio = {
      tipo: 'Proposta',
      deletado: 0,
      seguradora: data.seguradora,
      status: data.situacao === 'TODAS' ? 0 : data.situacao,
      vigenciaInicio: `${ dateStart.getDate() < 10 ? '0' + dateStart.getDate() : dateStart.getDate() }/${ dateStart.getMonth() + 1 < 10 ? `0${ dateStart.getMonth() + 1 }` : dateStart.getMonth() + 1 }/${ dateStart.getFullYear()}`,
      vigenciaFim: `${ dateEnd.getDate() < 10 ? '0' + dateEnd.getDate() : dateEnd.getDate() }/${ dateEnd.getMonth() + 1 < 10 ? `0${ dateEnd.getMonth() + 1 }` : dateEnd.getMonth() + 1 }/${ dateEnd.getFullYear()}`,
    };

    if( data.seguradora === 'TODAS' ) {
      delete filtrosRelatorio.seguradora;
    }

    if( data.seguradora !== 'TODAS') {
      for( let i = 0; i < seguradoras.length; i ++ ) {
        if( seguradoras[ i ].id === data.seguradora ) {
          data.seguradoraNome = seguradoras[ i ].nome;
        }
      }
    } else {
      data.seguradoraNome = 'TODAS';
    }

    try {
      await propostas.getPropostas( filtrosRelatorio );

    } catch {
      alert.addToast({ message: 'Erro inesperado, tente novamente' });
    } finally {
      setFetchingPropostas(false);
    }
  }

  async function getPropostasIntervalo() {
    setFetchingPropostas(true);

    try {
      const today = new Date();

      if( acaoStorage.type === 'dia') {
        propostas.getPropostasIntervalo({
          acao: 'report',
          dataInicio: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)),
          dataFim: new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)),
        });

        setFetchingPropostas( false );
      } else {
        const startMonth = startOfMonth( today );
        const endMonth = endOfMonth( today );

        if( acaoStorage.type === 'mes') {
          propostas.getPropostasIntervalo({
            acao: 'report',
            dataInicio: startMonth,
            dataFim: endMonth,
          });

          setFetchingPropostas( false );
        }
      }
    } catch(error) {
      alert.addToast({ message: 'Erro ao buscar dados das últimas propostas' });
      setFetchingPropostas( false );
    }
  };

  useEffect(() => {
    if( acaoStorage ) {
      getPropostasIntervalo();
    }

    propostas.clearPropostas();
  }, []);

  return (
    <Formik
      onSubmit={handleSubmit}
      validateOnMount
      initialValues={INITIAL_VALUES}
    >
      {formik => (
        <Paper className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title} variant="h6" id="usuarios">
              Propostas
            </Typography>
          </Toolbar>
          <Form className={formClassName} {...rest}>
            <Box className={classes.inputsWrapper}>
              <FormikDate
                className={classes.input}
                label="Data início"
                name="start_date"
              />
              <FormikDate
                className={classes.input}
                label="Data Fim"
                name="end_date"
              />
            </Box>

            <LoadingButton
              className={classes.button}
              type="submit"
              disabled={!formik.isValid}
              loading={fetchingPropostas}
              variant="contained"
              color="primary"
              size="large"
              endIcon={<SearchIcon />}
            >
              Consultar
            </LoadingButton>
          </Form>
        </Paper>
      )}
    </Formik>
  );
}
