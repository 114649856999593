import React from 'react';
import clsx from 'clsx';

import MaterialAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { ConfirmWrapper } from '~/components/ConfirmWrapper';
import { useStore } from '~/store';
import { useHistory } from 'react-router-dom';

import styles from './AppBar.styles';

import logo from '~/assets/logo-white.png';

export function AppBar({ openDrawer }) {
  const { auth } = useStore();
  const history = useHistory()

  const classes = styles();

  const appBarClassName = clsx({
    [classes.appBar]: true,
    [classes.appBarShift]: false
  });

  const iconButtonClassName = clsx({
    [classes.menuButton]: true,
    [classes.menuButtonHidden]: false
  });

  function handleLogout() {
    auth.signout();

    history.push('/login');

    localStorage.clear();
  }

  return (
    <MaterialAppBar position="absolute" className={appBarClassName}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={openDrawer}
          className={iconButtonClassName}
        >
          <MenuIcon />
        </IconButton>
        <div className={ classes.title } />

        <div className={ classes.title }>
          <img src={ logo } alt="TDA Seguros" className={ classes.logo }/>
        </div>

        <Typography className={classes.userName}>{auth.user?.imobiliaria}</Typography>
        <ConfirmWrapper
          action="Sair"
          message="Tem certeza que deseja sair?"
          onConfirm={handleLogout}
        >
          <IconButton color="inherit">
            <ExitToAppIcon />
          </IconButton>
        </ConfirmWrapper>
      </Toolbar>
    </MaterialAppBar>
  );
}
