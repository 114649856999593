import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const Styles = makeStyles(theme => ({
  appBar: {
    paddingTop: 5,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  toolbar: {
    paddingRight: 24
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    // position: 'relative',
    flexGrow: 1,
  },
  userName: {
    marginRight: 5
  },
  divEmpty: {
    flexGrow: 1
  },
   logo: {
     width: 120,
   },
}));

export default Styles;
