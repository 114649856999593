import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  list: {
    display: 'flex',
    position: "absolute",
    right: 10,
  },
  item: {
    whiteSpace: 'nowrap',
    opacity: 1,
    width: 150,
    borderRadius: theme.shape.borderRadius
  },
  selected: {
    background: 'transparent !important',
    opacity: 1
  }
}));

export default Styles;
