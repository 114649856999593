import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'formik';
import axios from 'axios';

import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';

import { addYears } from 'date-fns';

import PersonIcon from '@material-ui/icons/Person';

import { cpfCnpjMask, cepMask } from '~/utils/helpers/masks';

import apiConfig from '~/config/api-config';

import {
  FormikTextField,
  LoadingButton,
  FormikMask,
  FormikDate,
  FormikMaskedValue
} from '~/components';
import { tiposPessoa, estados } from '~/utils/constants';

import useStyles from './styles';
import { useStore } from '~/store';

export function PropostaForm({ formik }) {
  const token = localStorage.getItem('@TDA_token');

  const { alert } = useStore();

  const api = axios.create({ ...apiConfig });
  api.defaults.headers.token = token;

  const [fetchingCep, setFetchingCep] = useState(false);

  const [ imobiliariaRec, setImobiliariaRec ] = useState({});

  const classes = useStyles();

  const formRef = useRef();

  async function getCep() {
    try {
      setFetchingCep(true);

      const cep = formik.values.localCep.replace(/\D+/, '');

      const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      const { erro, logradouro, complemento, bairro, localidade, uf } = data;

      if( !erro ) {
        formik.setValues({
          ...formik.values,
          localEndereco: logradouro,
          localComplemento: complemento,
          localBairro: bairro,
          localCidade: localidade,
          localEstado: uf
        });
      } else {
        formik.setFieldError('localCep', 'CEP informado inexistente!');

        formik.setFieldValue('localCep', '', false );
      }
    } catch {
      alert.addToast({ message: 'Nenhum endereço encontrado' });
    } finally {
      setFetchingCep(false);
    }
  }

  async function getImobiliaria() {
    const response = await api.get('/imobiliarias/imobiliaria');

    setImobiliariaRec( response.data );
  };

  useEffect(() => {
    getImobiliaria();
  }, []);

  useEffect(() => {
    const dataInicio = new Date(formik.values.vigenciaInicio);
    const dataFim = new Date(formik.values.vigenciaFim);

    formik.setFieldValue('vigenciaFim', addYears( dataInicio, 1), true);
  }, [ formik.values.vigenciaInicio ]);

  useEffect(() => {
    const data = JSON.parse( localStorage.getItem('TDA_Seguros@Simulacao'));

    if( data ) {
      formik.setValues({
        proprietarioNome: data.proprietarioNome,
        proprietarioTipo: data.proprietarioTipo,
        proprietarioRegistro: data.proprietarioRegistro,
        inquilinoNome: data.inquilinoNome,
        inquilinoTipo: data.inquilinoTipo,
        inquilinoRegistro: data.inquilinoRegistro,
        vigenciaInicio: new Date(),
        vigenciaFim: addYears( new Date(), 1),
        dataProposta: data.dataProposta || new Date(),
        localCep: data.localCep,
        localEndereco: data.localEndereco,
        localNumero: data.localNumero,
        localComplemento: data.localComplemento,
        localBairro: data.localBairro,
        localCidade: data.localCidade,
        localEstado: data.localEstado,
      })
    }

  }, [ localStorage.getItem('TDA_Seguros@Simulacao')]);

  return (
    <Form className={classes.form} id="cadastro_form" ref={formRef}>
      <Box className={classes.inputsRow}>
        <Box className={classes.inputsColumn}>
          <div className={ classes.dataLabel }>
            <PersonIcon style={{ fontSize: 30 }} />

            <span style={{ marginLeft: 15, fontWeight: 'bold', fontSize: 20 }}>
              Dados do Proprietário
            </span>
          </div>

          <FormikTextField
            name="proprietarioNome"
            label="Nome do Proprietário"
          />
          <FormikTextField
            className={classes.select}
            name="proprietarioTipo"
            select
            label="Tipo"
          >
            {tiposPessoa.map(tipo => (
              <MenuItem key={tipo.value} value={tipo.value}>
                {tipo.label}
              </MenuItem>
            ))}
          </FormikTextField>
          <FormikMask
            name="proprietarioRegistro"
            label="CPF/CNPJ do Proprietário"
            mask={cpfCnpjMask}
            placeholder="000.000.000-00"
          />
        </Box>
        <Box className={classes.inputsColumn}>
          <div className={ classes.dataLabel }>
            <PersonIcon style={{ fontSize: 30 }} />

            <span style={{ marginLeft: 15, fontWeight: 'bold', fontSize: 20 }}>
              Dados do Inquilino
            </span>
          </div>

          <FormikTextField name="inquilinoNome" label="Nome do Inquilino" />
          <FormikTextField
            className={classes.select}
            name="inquilinoTipo"
            select
            label="Tipo"
          >
            {tiposPessoa.map(tipo => (
              <MenuItem key={tipo.value} value={tipo.value}>
                {tipo.label}
              </MenuItem>
            ))}
          </FormikTextField>
          <FormikMask
            name="inquilinoRegistro"
            label="CPF/CNPJ do Inquilino"
            mask={cpfCnpjMask}
            placeholder="000.000.000-00"
          />
        </Box>
      </Box>

      <Box className={classes.separator} />

      <Box className={classes.inputsRow}>
        <Box className={classes.inputsColumn}>
          <FormikDate
            className={classes.data}
            name="vigenciaInicio"
            disablePast
            label="Vigência do Contrato (Início)"
            id="dataInicio"
          />
        </Box>
        <Box className={classes.inputsColumn}>
          <FormikDate
            className={classes.data}
            name="vigenciaFim"
            disablePast
            label="Vigência do Contrato (Fim)"
            id="dataFim"
            disabled
          />
        </Box>
      </Box>

      <Box className={classes.separator} />

      <Box style={{ alignItems: 'baseline'}} className={classes.inputsRow}>
        <Box className={classes.inputsColumn}>
          <Box className={classes.cepWrapper}>
            <FormikMaskedValue              
              className={classes.cepField}
              name="localCep"
              label="CEP"
              mask={cepMask}
              placeholder="00000-000"
            />
            <LoadingButton
              className={classes.cepButton}
              variant="contained"
              onClick={getCep}
              loading={fetchingCep}
              disabled={formik.values.localCep?.length < 10}
              color="primary"
            >
              Buscar CEP
            </LoadingButton>
          </Box>
        </Box>

        <Box className={classes.inputsColumn}>
          <FormikDate
            className={classes.data}
            name="dataProposta"
            disabled
            label="Data da proposta"
            id="dataProposta"
          />
        </Box>
      </Box>

      <Box className={classes.inputsRow}>
        <Box className={classes.inputsColumn}>
          <FormikTextField name="localEndereco" label="Endereço" />
          <FormikTextField type="number" name="localNumero" label="Número" />
          <FormikTextField name="localComplemento" label="Complemento" />
        </Box>
        <Box className={classes.inputsColumn}>
          <FormikTextField name="localBairro" label="Bairro" />
          <FormikTextField name="localCidade" label="Cidade" />
          <FormikTextField 
            className={classes.select}
            name="localEstado"
            select
            label="Estado"
          >
            {estados.map(tipo => (
              <MenuItem key={tipo.value} value={tipo.value}>
                {tipo.label}
              </MenuItem>
            ))}
          </FormikTextField>
        </Box>
      </Box>
    </Form>
  );
}
