import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden'
  },
  fab: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: theme.spacing(13),
    right: theme.spacing(12),
  },

  fab2: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: theme.spacing(12),
    right: theme.spacing(2),
  },
  tabContent: {
    position: 'relative',
    minHeight: 200
  },
}));

export default Styles;
