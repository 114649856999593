import React from 'react';

import MaterialTextField from '@material-ui/core/TextField';

import useStyles from './TextField.styles';

export function TextField({ children, helperText, ...rest }) {
  const classes = useStyles();

  return (
    <MaterialTextField
      variant="outlined"
      size="small"
      className={classes.field}
      FormHelperTextProps={{ className: classes.helperText }}
      helperText={!rest.select ? helperText : undefined}
      {...rest}
    >
      {children}
    </MaterialTextField>
  );
}
