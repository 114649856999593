import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';

import SendIcon from '@material-ui/icons/Send';
import MenuItem from '@material-ui/core/MenuItem';

import api from '~/services/api';

import {
  CONTACT_INITIAL,
  contactSchema,
  tiposContato
} from '~/utils/constants';
import { FormikTextField, LoadingButton, FormikMask } from '~/components';
import { useStore } from '~/store';

import useStyles from './styles';

export function AddContactForm({ contactData, close }) {
  const [sending, setSending] = useState(false);

  const { contacts, alert } = useStore();

  const classes = useStyles();

  async function handleSubmit(dataContact, options) {
    setSending(true);

    if( contactData ) {
      try {
        delete dataContact.actions;

        const { data } = await api.alterarContact( dataContact );

        if( data.success ) {
          alert.addToast({
            type: 'success',
            message: 'Contato alterado com sucesso!'
          });

          contacts.getContacts();

          setSending(false);

          close();
        } else {
          alert.addToast({ message: 'Erro inesperado ao alterado' });
        }
      } catch(error) {
        alert.addToast({ message: 'Erro inesperado ao alterado' });
      }
    } else {
      try {
        const { success } = await contacts.addContact(dataContact);

        if (success) {
          options.resetForm();

          close();
        }
      } catch(error) {
        alert.addToast({ message: 'Erro inesperado, tente novamente' });
      } finally {
        setSending(false);
      }
    }
  }

  function setTipoContato( value ) {
    switch(value) {
      case 'Atendente':
        return 1;
      case 'Corretor':
        return 2;
      case 'Diretor':
        return 3;
      case 'Estagiário':
        return 4;
      case 'Financeiro':
        return 5;
      case 'Gerente':
        return 6;
      case 'Jurídico':
        return 7;
      case 'Presidente':
        return 8;
      case 'Proprietário':
        return 9;
      case 'Sócio':
        return 10;
      case 'Vendedor':
        return 11;
    };
  };

  // useEffect(() => {
  //   if( contactData ) {
  //     contactData.tpContato = setTipoContato(contactData.tpContato);
  //   }
  // }, []);

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={contactData || CONTACT_INITIAL}
      validationSchema={!contactData && contactSchema}
      validateOnMount
    >
      {formik => (
        <Form className={classes.form}>
          <FormikTextField name="tpContato" select label="Tipo">
            {tiposContato.map(tipo => (
              <MenuItem key={tipo.value} value={tipo.value}>
                {tipo.label}
              </MenuItem>
            ))}
          </FormikTextField>
          <FormikTextField name="nome" label="Nome" />
          <FormikMask
            name="telefone"
            label="Telefone"
            placeholder="(00) 00000-0000"
          />
          <FormikMask
            name="celular"
            label="Celular"
            placeholder="(00) 00000-0000"
          />
          <FormikTextField name="email" label="E-mail" autoComplete="email" />
          <LoadingButton
            className={classes.button}
            type="submit"
            disabled={!formik.isValid || sending}
            loading={sending}
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            size="large"
          >
            Salvar
          </LoadingButton>
        </Form>
      )}
    </Formik>
  );
}
