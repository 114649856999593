import React from 'react';
import { useField } from 'formik';

import { DateInput } from '~/components/Inputs/Date';

export const FormikDate = props => {
  const [field, meta, helpers] = useField(props);

  return (
    <DateInput
      error={meta.touched ? meta.error : undefined}
      {...props}
      {...field}
      value={field.value}
      onChange={value => helpers.setValue(value)}
    />
  );
};
