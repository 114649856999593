import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

export function DateInput(props) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        id="date-input"
        label="Data"
        KeyboardButtonProps={{
          'aria-label': 'data'
        }}
        invalidDateMessage="Data inválida"
        invalidLabel="Data inválida"
        maxDateMessage="Data além do limite permitido"
        minDateMessage="Data abaixo do limite permitido"
        autoOk
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}
