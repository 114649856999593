import { object, string, number } from 'yup';

import estadosJson from '~/utils/mock/estados.json';
import { BASICA, NAO_CONTRATAR, tiposAssistencia } from './assistencias';

export const SIMULACAO_INITIAL = {
  tipoImovel: '',
  tipoOcupacao: '',
  tipoAtividade: '',
  tipoCobertura: '1',
  valorAluguel: '',
  assistencia24: BASICA
};

export const RESIDENCIAL = 'Residencial';
export const COMERCIAL = 'Comercial';

export const simulacaoSchema = object().shape({
  valorAluguel: number()
    .typeError('Valor do aluguel deve ter formato número')
    .required('Valor do aluguel é obrigatório'),
  tipoImovel: string()
    .typeError('Tipo do imóvel deve ter formato texto')
    .required('Tipo do imóvel é obrigatório'),
  tipoOcupacao: string()
    .typeError('Tipo de ocupação deve ter formato texto')
    .when('tipoImovel', (tipoImovel, field) =>
      tipoImovel === RESIDENCIAL
        ? field.required('Tipo de ocupação é obrigatório')
        : field
    ),
  tipoAtividade: string()
    .typeError('Tipo de atividade deve ter formato texto')
    .when('tipoImovel', (tipoImovel, field) =>
      tipoImovel === COMERCIAL
        ? field.required('Tipo de atividade é obrigatório')
        : field
    ),
  tipoCobertura: string()
    .typeError('Tipo de cobertura deve ser um texto')
    .when('tipoImovel', (tipoImovel, field) =>
      tipoImovel === COMERCIAL
        ? field.required('Tipo cobertura é obrigatório')
        : field
    ),
  assistencia24: string()
    .typeError('Opção de assistência deve ser um texto')
    .required('Opção de assistência é obrigatório')
    .oneOf(
      [
        NAO_CONTRATAR,
        ...tiposAssistencia.map(tipoAssistencia => tipoAssistencia.value)
      ],
      'Deve ser uma das opções válidas'
    )
});

export const tiposImovel = [
  {
    id: 1,
    value: RESIDENCIAL,
    label: 'Residencial'
  },
  {
    id: 2,
    value: COMERCIAL,
    label: 'Comercial'
  }
];

export const tiposOcupacaoResidencial = [
  {
    value: 'Apartamento',
    label: 'Apartamento'
  },
  {
    value: 'Casa',
    label: 'Casa'
  }
];

export const tiposCobertura = [
  {
    value: '0',
    label: 'Somente Prédio',
    value_bd: 'Prédio',
    helpText: 'Cobre a construção civil e/ou edificação do estabelecimento segurado, necessário para a operação industrial, comercial ou de prestação de serviços do Segurado.'
  },
  {
    value: '1',
    label: 'Prédio + Conteúdo do Inquilino',
    value_bd: 'Prédio + Conteúdo',
    helpText: 'Cobre o prédio, mais as máquinas, equipamentos, instrumentos, mobiliário, utensílios e suas respectivas instalações.'
  }
];

export const coberturas = [
  {
    selectable: false,
    select: true,
    editable: true,
    value: 'incendio_raio_explosao',
    label: 'Incêndio / Raio / Explosão',
    responseKey: 'coberturaIncendioRaioExplosao'
  },
  {
    selectable: false,
    select: true,
    editable: false,
    value: 'perda_pagamento_aluguel',
    label: 'Perda Aluguel',
    responseKey: 'coberturaPerdaPagamentoAluguel'
  },
  {
    selectable: true,
    select: true,
    editable: true,
    value: 'responsabilidade_civil',
    label: 'Responsabilidade Civil',
    responseKey: 'coberturaResponsabilidadeCivil'
  },
  {
    selectable: true,
    select: true,
    editable: true,
    value: 'quebra_vidros',
    label: 'Vendaval / Granizo',
    responseKey: 'coberturaQuebraVidros'
  },
  {
    selectable: true,
    select: true,
    editable: true,
    value: 'danos_eletricos',
    label: 'Danos Elétricos',
    responseKey: 'coberturaDanosEletricos'
  }
];

export const tiposPessoa = [
  {
    value: 'PF',
    label: 'Tipo de Cliente PF'
  },
  {
    value: 'PJ',
    label: 'Tipo de Cliente PJ'
  }
];

export const estados = estadosJson.map(estado => ({
  value: estado.sigla,
  label: estado.nome
}));
