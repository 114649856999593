import React, { useState, useEffect } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import HomeWork from '@material-ui/icons/HomeWork';
import ContactsIcon from '@material-ui/icons/ContactPhone';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import CircularProgress from '@material-ui/core/CircularProgress';

import { useStore } from '~/store';

import { ConfirmWrapper } from '~/components/ConfirmWrapper';

import useStyles from './styles';
import { Imobiliaria } from './Imobiliaria';
import { Contacts } from './Contacts';

import Modal from '~/components/ModalImobiliaria';

import api from '~/services/api';

const tabs = [
  {
    label: 'Imobiliária',
    icon: <HomeWork />,
    component: <Imobiliaria />
  },
  {
    label: 'Contatos',
    icon: <ContactsIcon />,
    component: <Contacts />
  }
];

function TabPanel({ children, value, index, ...rest }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export function MinhaImobiliaria() {
  const [value, setValue] = useState(0);

  const [ open, setOpen ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const classes = useStyles();

  const handleChange = (e, index) => {
    setValue(index);
  };

  async function handleDelete() {
    setLoading(true);

    try {
      const { data } = await api.deletarImobiliaria( imobiliaria.data.pkImobiliaria );

      if( data.success ) {
        alert.addToast({
          type: 'success',
          message: 'Imobiliária deletada com sucesso!'
        });

        setLoading(false);
      } else {
        alert.addToast({ message: 'Erro inesperado ao deletar' });
      }

      setLoading(false);
    } catch {
      alert.addToast({ message: 'Erro inesperado ao deletar' });
    }
  };

  const { imobiliaria, alert } = useStore();

  useEffect(() => {
    imobiliaria.getImobiliaria();
  }, []);

  return (
    <>
      <Paper className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="imobiliária menu"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((tab, index) => (
              <Tab
                key={tab.label}
                label={tab.label}
                icon={tab.icon}
                disabled={tab.disabled}
                id={`imobiliária-aba-${index}`}
                aria-controls={`imobiliária-tabpanel-${index}`}
              />
            ))}
          </Tabs>
        </AppBar>
        {tabs.map((tab, index) => (
          <TabPanel
            className={classes.tabContent}
            key={tab.label}
            value={value}
            index={index}
          >
            {tab.component}
          </TabPanel>
        ))}
      </Paper>

      {
        value > 0 ? <></> : (
          <>
            <Fab
              className={ classes.fab }
              color="primary"
              aria-label="print"
              aria-haspopup="true"
              onClick={() => setOpen(true)}
            >
              <EditIcon />
            </Fab>

            <ConfirmWrapper
              className={ classes.fab2 }
              action="Deletar"
              message="Tem certeza que deseja deletar a imobiliária?"
              onConfirm={handleDelete}
            >
              <IconButton color="inherit">
                <Fab
                  color="secondary"
                >
                  { loading ? <CircularProgress size={ 30 } style={{ color: '#FFF' }}/> : <DeleteIcon /> }
                </Fab>
              </IconButton>
            </ConfirmWrapper>
          </>
        )
      }

      <Modal open={ open } setOpen={ setOpen } />
    </>
  );
}
