import React, { useEffect } from 'react';

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStore } from '~/store';
import { transformImobiliariaData } from '~/utils/helpers/imobiliaria';

import useStyles from './styles';

export function Imobiliaria() {
  const { imobiliaria } = useStore();

  const classes = useStyles();

  const imobiliariaArrayData = transformImobiliariaData(imobiliaria.data);

  useEffect(() => {
    imobiliaria.getImobiliaria();
  }, []);

  return (
    <Table className={classes.table}>
      <TableBody className={classes.body}>
        {imobiliaria.fetching && (
          <TableRow className={classes.hiddenRow}>
            <TableCell>
              <Paper elevation={4} className={classes.progressContainer}>
                <CircularProgress size={30} color="primary" thickness={6} />
              </Paper>
            </TableCell>
          </TableRow>
        )}
        {imobiliariaArrayData.map(item => (
          <TableRow className={classes.row} key={item.id}>
            <Typography className={classes.label} component="td">
              {item.label}
            </Typography>
            <Typography className={classes.content} component="td">
              {item.content}
            </Typography>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
