import React, { useState } from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import { AddContactModal } from '../AddContact';

import useStyles from './styles';

export function ContactsTableToolbar() {
  const [addContactOpen, setAddContactOpen] = useState(false);

  const classes = useStyles();

  function handleAddContact() {
    setAddContactOpen(true);
  }

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6">
        Contatos
      </Typography>

      <Tooltip title="Adicionar contato">
        <IconButton
          aria-label="adicionar contato"
          onClick={handleAddContact}
          edge="end"
          className={ classes.addPersonalButton }
        >
          <PersonAddIcon />
        </IconButton>
      </Tooltip>

      <AddContactModal
        open={addContactOpen}
        close={() => setAddContactOpen(false)}
      />
    </Toolbar>
  );
}
