import React from 'react'
import clsx from 'clsx'

import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import { DrawerItens } from './DrawerItens'
import styles from './AppDrawer.styles'

export function MobileAppDrawer ({ drawerOpen, closeDrawer }) {
  const classes = styles()

  const drawerPaperClassName = clsx({
    [classes.drawerPaper]: true,
    [classes.drawerPaperClose]: !drawerOpen
  })

  return (
    <Drawer
      classes={{ paper: drawerPaperClassName }}
      open={drawerOpen}
      onClose={closeDrawer}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={closeDrawer} size='small'>
          <ChevronLeftIcon className={classes.closeIcon} />
        </IconButton>
      </div>
      <Divider />
      <DrawerItens onSelectItem={closeDrawer} />
    </Drawer>
  )
}
