/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import MaskedInput from 'react-text-mask';

import { TextField } from '~/components';

function MaskInput({ mask, inputRef, ...rest }) {
  return (
    <MaskedInput
      mask={mask}
      showMask
      guide={false}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...rest}
    />
  );
}

export function TextFieldMask({ mask, ...rest }) {
  return (
    <TextField
      {...rest}
      inputProps={{ mask }}
      InputProps={{ inputComponent: MaskInput }}
    />
  );
}
