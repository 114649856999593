import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import { AppLayout, AuthLayout } from '~/pages/_layouts'

import PrivateRoute from './private'
import GuestRoute from './guest'
import AppRoutes from './appRoutes'
import AuthRoutes from './authRoutes'

export default function Routes () {
  return (
    <Switch>
      <PrivateRoute path='/app'>
        <AppLayout>
          <AppRoutes />
        </AppLayout>
      </PrivateRoute>
      <GuestRoute path='/'>
        <AuthLayout>
          <AuthRoutes />
        </AuthLayout>
      </GuestRoute>
      <Redirect to='/login' />
    </Switch>
  )
}
