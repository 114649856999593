import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

import { version } from '../../package.json';

export default function EnvironmentBox() {
  const url = window.location.href;
  let env = '';
  if (url.includes('localhost')) {
    env = 'Desenvolvimento';
  } else if( process.env.REACT_APP_NODE_ENV === 'hmg') {
      env = 'Homologação';
    } else if( process.env.REACT_APP_NODE_ENV === 'prod') {
      env = 'Produção';
    }

  return (
    <Box pt={4}>
      <Typography variant="body2" color="textSecondary" align="center">
        {`${env} - ${ version }`}
        {/* <Link color="inherit" href="https://tdaseguros.com.br/">
          Tda Seguros
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'} */}
      </Typography>
    </Box>
  );
}
