import React from 'react';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import useStyles from './LoadingButton.styles';

export function LoadingButton({ loading, children, disabled, ...rest }) {
  const classes = useStyles();

  const progressClassName = clsx({
    [classes.disabledProgress]: disabled,
    [classes.progress]: true
  });

  return (
    <Button disabled={disabled} {...rest}>
      {children}
      {loading && <LinearProgress size={20} className={progressClassName} />}
    </Button>
  );
}
