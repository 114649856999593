import React, { useState, useEffect } from 'react';

import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';

import { useStore } from '~/store';

import api from '~/services/api';

import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import DeleteIcon from '@material-ui/icons/Delete';

import ModalSelectCotacao from '~/components/ModalSelectCotacao';

import useStyles from './styles';

export function Cotacoes() {
  const [ getting, setGetting ] = useState({ load: false, id: 0 });
  const [ propostas, setPropostas ] = useState([]);
  const [ loadingOrders, setLoadingOrders ] = useState(false);

  const [ open, setOpen ] = useState(false);
  const [ simulacao, setSimulacao ] = useState([]);

  const { alert } = useStore();

  const classes = useStyles();

  async function handleSelectProposta( id ) {
    setGetting({ load: true, id });

    try {
      const { statusText, data } = await api.getCotacoesProposta(id);

      if( statusText === 'OK') {
        setGetting( false );

        localStorage.setItem('TDA_Seguros@Simulacao', JSON.stringify({ ...data.proposta, qtdParcelas: 1, status: 'outside' }));

        const simulacaoParcelas = data.cotacoesSeguradora.map( item => {
          const obj = item;

          obj.premioTotalParcelas = gerarParcelamento({
            valorTotal: item.valorPremioTotal,
            maxParcelas: 4
          });

          return obj;
        })

        setSimulacao( simulacaoParcelas );

        setOpen(true);
      } else {
        setGetting( false );

        alert.addToast({ message: 'Erro ao buscar proposta' });
      }
    } catch ( error ) {
      setGetting( false );

      alert.addToast({ message: 'Falha ao buscar cotações da proposta' });
    }
  };

  async function getCotacoes() {
    setLoadingOrders(true);

    try {
      const response = await api.getCotacoes();

      if( response.statusText === 'OK') {
        setLoadingOrders( false );

        setPropostas( response.data );

      } else {
        setLoadingOrders( false );

        alert.addToast({ message: 'Erro ao buscar cotações' });
      }
    } catch ( error ) {
      setLoadingOrders( false );

      alert.addToast({ message: 'Erro ao buscar cotações' });
    }
  };

  useEffect(() => {
    getCotacoes();
  }, []);

  return (
    <>
      <Paper className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6" id="usuarios">
            Consultar/Efetivar Cotações
          </Typography>
        </Toolbar>

        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Protocolo</TableCell>
              <TableCell>Imobiliária</TableCell>
              <TableCell>Data Proposta</TableCell>
              <TableCell>Nome/Email</TableCell>
              <TableCell>Vigência</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              !loadingOrders ?
                propostas.length === 0 ? (
                    <>
                      <TableCell></TableCell>

                      <TableCell></TableCell>

                      <TableCell></TableCell>

                      <TableCell></TableCell>

                      <TableCell></TableCell>

                      <TableCell></TableCell>
                    </>
                  )
                : (
                    propostas.map(row => (
                      <TableRow key={row.pkProposta}>
                      <TableCell>{row.pkProposta}</TableCell>
                      <TableCell>{row.nomeImobiliaria}</TableCell>
                      <TableCell>{row.dataProposta}</TableCell>
                      <TableCell>{row.cotacaoNomeInteressado}</TableCell>
                      <TableCell>{row.vigenciaFimCotacao}</TableCell>
                      <TableCell>
                            <IconButton
                              color="primary"
                              onClick={() => handleSelectProposta( row.pkProposta )}
                            >
                              { getting.load && getting.id === row.pkProposta ? <CircularProgress size={ 20 } /> : <PlaylistAddCheckIcon /> }
                            </IconButton>

                            {/* <IconButton disabled color="secondary">
                              { getting.load && getting.id === row.pkProposta ? <CircularProgress size={ 20 } /> : <DeleteIcon /> }
                            </IconButton> */}
                          </TableCell>
                    </TableRow>
                  ))
                )
              :
              <>
                <TableRow>
                  <TableCell><Skeleton animation="wave" /></TableCell>

                  <TableCell><Skeleton animation="wave" /></TableCell>

                  <TableCell><Skeleton animation="wave" /></TableCell>

                  <TableCell><Skeleton animation="wave" /></TableCell>

                  <TableCell><Skeleton animation="wave" /></TableCell>

                  <TableCell><Skeleton animation="wave" /></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell><Skeleton animation="wave" /></TableCell>

                  <TableCell><Skeleton animation="wave" /></TableCell>

                  <TableCell><Skeleton animation="wave" /></TableCell>

                  <TableCell><Skeleton animation="wave" /></TableCell>

                  <TableCell><Skeleton animation="wave" /></TableCell>

                  <TableCell><Skeleton animation="wave" /></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell><Skeleton animation="wave" /></TableCell>

                  <TableCell><Skeleton animation="wave" /></TableCell>

                  <TableCell><Skeleton animation="wave" /></TableCell>

                  <TableCell><Skeleton animation="wave" /></TableCell>

                  <TableCell><Skeleton animation="wave" /></TableCell>

                  <TableCell><Skeleton animation="wave" /></TableCell>
                </TableRow>
              </>
            }
          </TableBody>
        </Table>
      </Paper>

      <ModalSelectCotacao open={ open } setOpen={ setOpen } simulacao={ simulacao } />
    </>
  );
};

function gerarParcelamento ({ valorTotal, maxParcelas }) {
  let parcelas = [];

  if (!valorTotal) return parcelas;

  for (let i = 1; i <= maxParcelas; i++) {
    const value = valorTotal / i;
    const label = `${i}X ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(value)}`;
    const number = i;

    parcelas = [...parcelas, { value, label, number }];
  }
  return parcelas;
};
