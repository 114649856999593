import React, { useState } from 'react';
import { Form } from 'formik';
import axios from 'axios';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

import { cpfCnpjMask, cepMask } from '~/utils/helpers/masks';
import { FormikTextField, LoadingButton, FormikMask, FormikDate } from '~/components';
import { tiposPessoa, estados } from '~/utils/constants';

import useStyles from './styles';
import { useEffect } from 'react';

export function SignupForm({ formik, id }) {
  const [fetchingCep, setFetchingCep] = useState(false);

  const auth = JSON.parse( localStorage.getItem('@TDA_user'));

  const classes = useStyles();

  async function handleSearchCEP(cep) {
    setFetchingCep(true);

    const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

    setFetchingCep(false);
    return data;
  }

  async function getCep() {
    const result = await handleSearchCEP(formik.values.cep);

    const { erro, logradouro, bairro, localidade, uf } = result;

    if( !erro ) {
      formik.setValues({
        ...formik.values,
        endereco: `${ logradouro ? logradouro + ', ' : ''}${ bairro }`,
        localidade,
        uf
      });
    } else {
      formik.setFieldError('cep', 'CEP informado inexistente!');

      formik.setFieldValue('cep', '', false );
    }
  }

  const [ status, setStatus ] = useState(true);

  useEffect(() => {
    const date = new Date();
    const vigencia = new Date( date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);

    formik.setValues({
      ...formik.values,
      vigencia,
    });

    setStatus(handleGetStatus());
  }, []);

  function handleGetStatus() {
    const status = formik.values.status;

    switch(status) {
      case 'Ativo':
        return true;
      case 'Inativo':
        return false;
    }
  };

  useEffect(() => {
    formik.setFieldValue('status', status);
  }, [ status ]);

  function handleSetVigencia() {
    const dataInicio = new Date(formik.values.vigencia);
    const date = new Date();

    if( dataInicio < new Date()) {
      formik.setFieldValue('vigencia', new Date( date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    }
  };

  return (
    <Form className={classes.form} id="signup_form">
      <Box className={classes.inputsRow}>
        <Box className={classes.inputsColumn}>
          <FormikTextField name="nome" label="Nome da Imobiliária" />
        </Box>

        <Box className={classes.inputsColumn}>
          <FormikTextField type="number" name="responsavelCreci" label="Número do CRECI" />
        </Box>
      </Box>

      <Box className={classes.inputsRow}>
        <Box className={classes.inputsColumn}>
          <FormikTextField
            className={classes.select}
            name="tipo"
            select
            label="Tipo"
          >
            {tiposPessoa.map(tipo => (
              <MenuItem key={tipo.value} value={tipo.value}>
                {tipo.label}
              </MenuItem>
            ))}
          </FormikTextField>
        </Box>
        <Box className={classes.inputsColumn}>
          <FormikMask
            name="registro"
            label="CPF/CNPJ"
            mask={cpfCnpjMask}
            placeholder="000.000.000-00"
          />
        </Box>
      </Box>

      <Box className={classes.separator} />

      <Box className={classes.inputsRow} style={{ alignItems: 'baseline'}}>
        <Box className={classes.inputsColumn}>
          <FormikDate name="vigencia" label="Início de Vigência" />
        </Box>

        <Box className={ classes.inputsColumn}>
          <Box className={classes.cepWrapper}>
            <FormikMask
              onFocus={handleSetVigencia}
              className={classes.cepField}
              name="cep"
              label="CEP"
              mask={cepMask}
              placeholder="00000-000"
            />
            <LoadingButton
              className={classes.cepButton}
              variant="contained"
              onClick={getCep}
              loading={fetchingCep}
              disabled={formik.values.cep.length !== 8}
              color="primary"
            >
              Buscar CEP
            </LoadingButton>
          </Box>
        </Box>
      </Box>

      <Box className={ classes.inputsRow }>
        <Box className={classes.inputsColumn}>
          <FormikTextField onFocus={handleSetVigencia} name="endereco" label="Endereço" InputLabelProps={ formik.values.endereco !== '' ? { shrink: true } : {}} />
        </Box>
      </Box>

      <Box className={classes.inputsRow}>
        <Box className={classes.inputsColumn}>
          <FormikTextField name="localidade" label="Cidade" />
        </Box>

        <Box className={ classes.inputsColumn }>
          <FormikTextField
            className={classes.select}
            name="uf"
            select
            label="Estado"
          >
            {estados.map(tipo => (
              <MenuItem key={tipo.value} value={tipo.value}>
                {tipo.label}
              </MenuItem>
            ))}
          </FormikTextField>
        </Box>
      </Box>

      <div>
        <Typography style={{ marginLeft: 10 }} variant="h6">
          Dados Para Contato
        </Typography>

        <Box className={classes.separator} />

        <Box className={classes.inputsRow}>
          {
            !id
              ? <>
                  <Box className={ classes.inputsColumn }>
                    <FormikTextField name="contatoNome" label="Nome Contato"/>
                  </Box>

                  <Box className={ classes.inputsColumn }>
                    <FormikMask name="contatoFixo" label="Telefone Fixo" placeholder="(00) 0000-0000"/>
                  </Box>

                  <Box className={ classes.inputsColumn }>
                    <FormikMask name="contatoCelular" label="Telefone Celular" placeholder="(00) 00000-0000"/>
                  </Box>
                </>
              : <></>
          }
        </Box>
      </div>

      {
        !id ? (
          <>
            <div>
              <Typography style={{ marginLeft: 10 }} variant="h6">
                Cadastro de Usuários
              </Typography>

              <Box className={classes.separator} />
            </div>

            <Box className={classes.inputsRow}>
              <Box className={classes.inputsColumn}>
                <FormikTextField name="email" label="E-mail" autoComplete="email" />
                <FormikTextField name="nomeUsuario" label="Usuário Responsável" />
              </Box>
              <Box className={classes.inputsColumn}>
                <FormikTextField type="password" name="senha" label="Senha" />
                <FormikTextField type="password" name="confirmarSenha" label="Confirmar Senha" />
              </Box>
            </Box>

            <Box className={classes.separator} />
          </>
        ) :
          <>
            <Box className={classes.inputsRow}>
              <Box className={classes.inputsColumn}>
                <FormikTextField name="notas" label="Notas" multiline rows={2}/>
              </Box>

              <Box className={classes.inputsColumn}>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, width: '20%'}}>
                  <span style={{ fontSize: 18}}>Status</span>
                  <FormControlLabel
                    control={<Checkbox value={status} checked={status} disabled={ auth && auth.role !== 'AdmImobiliaria' } onChange={( event ) => setStatus( event.target.checked )} color="primary" />}
                    label={status ? 'Ativo' : 'Inativo'}
                  />
                </div>
              </Box>
            </Box>
          </>
      }
    </Form>
  );
}
