import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useStore } from '~/store';

function GuestRoute(props) {
  const { auth } = useStore();

  if (auth.token) {
    return <Redirect to="/app" />;
  }

  return <Route {...props} />;
}

export default GuestRoute;
