import React from 'react';

import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { AddContactForm } from './AddContactForm';
import useStyles from './styles';

export function AddContactModal({ open, close, contactData }) {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper className={classes.paper}>
        <Box className={classes.header}>
          <Typography variant="h5">Adicionar contato</Typography>
          <IconButton onClick={close} edge="end" color="secondary">
            <CloseIcon />
          </IconButton>
        </Box>
        <AddContactForm close={ close } contactData={ contactData } />
      </Paper>
    </Modal>
  );
}
