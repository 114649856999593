import React, { useEffect, useRef, useState, useCallback } from 'react';

import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import useStyles from './ConfirmWrapper.styles';

export function ConfirmWrapper({
  action,
  children,
  onConfirm,
  message,
  ...rest
}) {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const classes = useStyles();

  const timeout = useRef();

  const closeConfirmation = useCallback(() => {
    clearTimeout(timeout.current);
    setConfirmOpen(false);
  }, []);

  function handleClick() {
    setConfirmOpen(!confirmOpen);
  }

  useEffect(() => {
    if (confirmOpen) {
      timeout.current = setTimeout(closeConfirmation, 20000);
    }

    return () => closeConfirmation;
  }, [closeConfirmation, confirmOpen]);

  function handleClose() {
    closeConfirmation();
  }

  function handleConfirm() {
    closeConfirmation();
    onConfirm();
  }

  return (
    <Box onClick={handleClick} {...rest}>
      {children}
      <Modal open={confirmOpen} onClose={handleClose}>
        <Paper className={classes.root}>
          <Typography className={classes.title}>{action}</Typography>
          <Box className={classes.content}>
            <Typography className={classes.message}>{message}</Typography>
            <Box className={classes.buttons}>
              <Button
                className={classes.button}
                onClick={handleClose}
                variant="outlined"
                color="secondary"
              >
                Cancelar
              </Button>
              <Button
                className={classes.button}
                onClick={handleConfirm}
                variant="contained"
                color="primary"
              >
                Confirmar
              </Button>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
}

ConfirmWrapper.defaultProps = {
  message: 'Tem certeza que deseja executar essa ação?'
};
