import React from 'react';
import NumberFormat from 'react-number-format';

import { TextField } from '~/components';

function NumberInput({ inputRef, ...rest }) {
  return (
    <NumberFormat
      getInputRef={inputRef}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
      isNumericString
      prefix="R$ "
      {...rest}
    />
  );
}

export function NumberFormatInput({ inputProps, ...rest }) {
  return (
    <TextField
      {...rest}
      inputProps={inputProps}
      InputProps={{
        inputComponent: NumberInput
      }}
    />
  );
}
