import React, { useState, useEffect, useCallback } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { ConfirmWrapper } from '~/components/ConfirmWrapper';

import {
  useHistory,
} from 'react-router-dom';

import { useStore } from '~/store';
import {
  getComparator,
  stableSort,
  getColumns,
  getPropostasContent
} from '~/utils/helpers';
import { propostasDataTableProp } from '~/utils/constants';

import useStyles from './styles';
import { PropostasTableHeader } from './PropostasTableHeader';

import api from '../../../services/api';

export function PropostasTable({ handleOpenModal }) {
  const { propostas, alert } = useStore();

  const history = useHistory();

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  const classes = useStyles({ md, sm });

  const [ propostasContentData, setPropostasContentData ] = useState([]);

  const columns = getColumns(propostasDataTableProp, { md, sm, indexes: [0, 2] });

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('pkProposta');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [ loading, setLoading ] = useState({ load: false, id: 0, type: ''});

  const handleRequestSort = useCallback(
    (e, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  async function handleSelectProposta( id ) {
    setLoading({ load: true, id, type: 'Visibility'});

    const response = await api.getProposta( id );

    if( response.statusText === 'OK' ) {
      setLoading({ load: false, id: 0 });

      handleOpenModal( response.data );
    } else {
      alert.addToast({ message: 'Falha!' });

      setLoading({ load: false, id: 0 });
    }
  };

  async function handleSelectPropostaEdit( id ) {
    setLoading({ load: true, id, type: 'Edit'});

    const response = await api.getProposta( id );

    if( response.statusText === 'OK' ) {
      const {
        pkProposta,
        dataProposta,
        imovelTipoImovel: tipoImovel,
        imovelTipoOcupacao: tipoOcupacao,
        cobertura,
        assistencia24H: assistencia24,
        valorAluguel,
        imovelCodigoAtividade: tipoAtividade,
        seguradora,
        vigenciaInicio,
        vigenciaFim,
        proprietarioNome,
        proprietarioTipo,
        proprietarioRegistro,
        inquilinoNome,
        inquilinoTipo,
        inquilinoRegistro,
        localCep,
        localEndereco,
        localNumero,
        localComplemento,
        localBairro,
        localCidade,
        localEstado,
      } = response.data;

      localStorage.setItem('TDA_Seguros@Simulacao', JSON.stringify({
        pkProposta,
        dataProposta,
        tipoImovel,
        tipoOcupacao,
        tipoAtividade,
        tipoCobertura: cobertura || '',
        valorAluguel,
        assistencia24,
        seguradora,
        vigenciaInicio,
        vigenciaFim,
        proprietarioNome,
        proprietarioTipo,
        proprietarioRegistro,
        inquilinoNome,
        inquilinoTipo,
        inquilinoRegistro,
        localCep,
        localEndereco,
        localNumero,
        localComplemento,
        localBairro,
        localCidade,
        localEstado,
      }))

      setLoading({ load: false, id: 0 });

      history.push('/app/seguro-incendio');
    } else {
      alert.addToast({ message: 'Falha!' });

      setLoading({ load: false, id: 0 });
    }
  };

  async function handleSelectPropostaDelete( id ) {
    setLoading({ load: true, id, type: 'Delete'});

    const response = await api.deleteProposta( id );

    if( response.statusText === 'OK' ) {
      setLoading({ load: false, id: 0, type: ''});

      setPropostasContentData( getPropostasContent({ data: propostasContentData.filter( proposta => proposta.pkProposta !== id ) }))
    } else {
      alert.addToast({ message: 'Falha!' });

      setLoading({ load: false, id: 0 });
    }
  };

  useEffect(() => {
    setPropostasContentData( getPropostasContent({ data: propostas.data }))
  }, []);

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer className={ classes.table }>
          <Table aria-labelledby="propostas" aria-label="propostas">
            <PropostasTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(propostasContentData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  return (
                    <TableRow key={row.pkProposta}>
                      {columns.map(column =>
                        column.id === 'acoes'
                          ? <TableCell key={ column.id } className={ classes.actionsCell }>
                              <IconButton
                                onClick={() => handleSelectProposta( row.pkProposta )}
                                color="primary"
                              >
                                { loading.load && loading.type === 'Visibility' && loading.id === row.pkProposta ? <CircularProgress size={ 20 } /> : <VisibilityIcon /> }
                              </IconButton>

                              <IconButton
                                onClick={() => handleSelectPropostaEdit( row.pkProposta )}
                                color="primary"
                              >
                                { loading.load && loading.type === 'Edit' && loading.id === row.pkProposta ? <CircularProgress size={ 20 } /> : <EditIcon /> }
                              </IconButton>

                              <ConfirmWrapper
                                action="Excluir"
                                message="Tem certeza que deseja excluir esta proposta?"
                                onConfirm={() => handleSelectPropostaDelete( row.pkProposta )}
                              >
                                <IconButton
                                  color="secondary"
                                >
                                  { loading.load && loading.type === 'Delete' && loading.id === row.pkProposta ? <CircularProgress color="secondary" size={ 20 } /> : <DeleteIcon /> }
                                </IconButton>
                              </ConfirmWrapper>
                            </TableCell>
                          : <TableCell key={column.id}>{row[column.id]}</TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            classes={{
              toolbar: classes.toolbar,
              selectRoot: classes.selectRoot,
              actions: classes.actions
            }}
            labelRowsPerPage="Resultados por página"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={propostasContentData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </>
  );
}
