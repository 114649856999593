import React, {useState} from 'react';

import useStyles from './styles';

import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';

import { LoadingButton } from '~/components';

import cards from './cards';

import api from '../../services/api';
import { useStore } from '~/store';

export function ServicoResidencial() {
  const classes = useStyles();

  const { alert } = useStore();

  const [ open, setOpen ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [ serviceModal, setServiceModal ] = useState({});
  const [ emailPrimario, setEmailPrimario ] = useState('');
  const [ emailSecundario, setEmailSecundario ] = useState('');

  function handleOpenModelSendEmail( card ) {
    setServiceModal( card );

    setOpen( true );
  };

  async function handleSendEmailUrl() {
    if( emailPrimario !== '' || emailSecundario !== '') {
      setLoading( true );

      try {
        const response = await api.enviarUrlServico({
          emailPrimario,
          emailSecundario,
          url: serviceModal.url,
        });

        if( response.statusText === 'OK' ) {
          alert.addToast({ type: 'success', message: 'Enviado com sucesso!' });

          setEmailPrimario('');
          setEmailSecundario('');

          setLoading( false );
          setOpen( false );
        } else {
          alert.addToast({ message: 'Falha!' });

          setLoading( false );
        }
      } catch (error) {
        alert.addToast({ message: 'Falha!' });

        setLoading( false );
      }
    } else {
      alert.addToast({ message: 'Ao menos 1 email deve ser informado!' });
    }
  };

  function handleClose() {}

  return(
    <>
      <Container className={classes.root} maxWidth="lg">
        <Grid container spacing={3}>
          {
            cards.map( card => (
              <Grid key={ card.title } item xs={3} md={3} lg={3}>
                <Card className={classes.card}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={ card.image }
                      title={ card.title }
                    />
                    <CardContent>
                      <Typography gutterBottom variant="body1">
                        { card.title }
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => window.open( card.url )}
                    >
                      Abrir
                    </Button>

                    <Button
                      size="small"
                      color="primary"
                      onClick={() => handleOpenModelSendEmail( card )}
                    >
                      Enviar
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
          }
        </Grid>
      </Container>

      <Modal
        open={open}
        className={classes.modal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <h3 id="simple-modal-title">{ serviceModal.title }</h3>

          <div className="">
            <TextField
              value={ emailPrimario }
              type="email"
              onChange={ event => setEmailPrimario( event.target.value )}
              className={classes.input}
              label="Digite aqui um email para receber o link" variant="outlined"
            />
            <TextField
              value={ emailSecundario }
              type="email"
              onChange={ event => setEmailSecundario( event.target.value )}
              className={classes.input}
              label="Digite aqui outro email para receber o link" variant="outlined"
            />
          </div>

          <div className={classes.buttonView}>
            <LoadingButton
              size="small"
              className={classes.button}
              loading={loading}
              color="primary"
              onClick={handleSendEmailUrl}
            >
              Enviar
            </LoadingButton>

            <Button
              size="small"
              className={classes.button}
              color="secondary"
              onClick={() => setOpen( false )}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
