import axios from 'axios';

import apiConfig from '~/config/api-config';

import mockBankData from '~/utils/mock/bankData.json';
import seguradoras from '~/utils/mock/seguradoras.json';

const createApi = () => {
  const api = axios.create({ ...apiConfig });

  const setAuthToken = token => {
    api.defaults.headers.token = token;
  };

  const removeAuthToken = () => {
    api.defaults.headers.token = '';
  };

  // Authentication
  const login = credentials => api.post('/auth/login', credentials);
  const logout = () => api.get('/auth/logout');

  // Imobiliária
  const getImobiliaria = () => api.get('/imobiliarias/imobiliaria');
  const inserirImobiliaria = data => api.post('/imobiliarias/inserir', data);
  const alterarImobiliaria = data => api.post('/imobiliarias/alterar', data)
  const deletarImobiliaria = id => api.get(`/imobiliarias/deletar/${ id }`)
  const verifyCredentials = id => api.post(`/imobiliarias/credenciais`, { id });

  // Users
  const getUsers = () => api.get('/imobiliarias/usuarios');

  const addUser = userData => api.post('/usuarios/inserir', userData);
  const updateUser = (userId, userData) =>
    api.post(`/imobiliarias/admin/alterar-usuario/${userId}`, userData);

  // Contacts
  const getContacts = () => api.get('/imobiliarias/contatos');
  const addContact = contactData => api.post('/imobiliarias/contato', contactData);
  const alterarContact = contactData => api.post(`/imobiliarias/contato/alterar`, contactData);
  const deleteContact = id => api.get(`/imobiliarias/contato/deletar/${ id }`);

  // Bank Data
  const getBankData = () =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve({ data: mockBankData });
      }, 1500);
    });

  const addBankData = bankData =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve({
          data: { id: Math.round(Math.random() * 1000), ...bankData }
        });
      }, 1500);
    });

  // Parametros comerciais
  const getOcupacoes = () => api.get('/parametros-comerciais/tipos-ocupacao');

  // Assistencias
  const getAssistencias = params =>
    api.get('/seguradoras/assistencias', { params });

  const getAssistenciasExterna = params =>
    api.get('/seguradoras/assistencias-externa', { params });

  // Simulacao
  const getSimulacao = data => api.post('/propostas/simulacao', data);
  const getSimulacaoExterna = data => api.post('/propostas/simulacao-externa', data);
  const enviarPropostaSeguro = data => api.post('/propostas/inserir', data);
  const singUpSimulacao = () =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve({ token: '' });
      }, 1500);
    });
  const confirmarSeguro = () =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 1500);
    });

  // Propostas
  const getSeguradoras = () =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve({ data: seguradoras });
      }, 1500);
    });

  const getPropostas = data => api.post('/propostas/proposta/relatorio', data);
  const getProposta = id => api.get(`/propostas/detalhar-proposta/${id}`);
  const alterarProposta = ( id, data ) => api.put(`/propostas/alterar/${id}`, data );
  const deleteProposta = id => api.delete(`/propostas/deletar/${id}`);
  const searchPropostas = search => api.get(`/propostas/search/${search}`);
  const getPropostasIntervalo = filtros => api.post('/propostas/intervalo', filtros);

  const salvarCotacao = data => api.post('/propostas/salvar-cotacao', data);
  const getCotacoes = () => api.get('/propostas/cotacoes');
  const getCotacoesProposta = id => api.get(`/propostas/cotacoes-proposta/${id}`);
  const sendCotacaoPDF = ( email, data ) => api.post('/propostas/cotacao-pdf', data, { headers: { email }});

  const enviarUrlServico = async data => api.post('/servicos/enviar-url', data);

  const sendPass = data => api.post('/usuarios/recuperar-senha', data);

  const getConfigVigenciaCotacao = () => api.get('/config/vigencia-cotacao');


  return {
    setAuthToken,
    removeAuthToken,

    login,
    logout,
    sendPass,

    getUsers,
    addUser,
    updateUser,

    getImobiliaria,
    inserirImobiliaria,
    alterarImobiliaria,
    deletarImobiliaria,
    verifyCredentials,

    getContacts,
    addContact,
    alterarContact,
    deleteContact,

    getBankData,
    addBankData,

    getOcupacoes,

    getAssistencias,
    getAssistenciasExterna,

    getSimulacao,
    getSimulacaoExterna,
    singUpSimulacao,
    enviarPropostaSeguro,
    confirmarSeguro,

    getSeguradoras,
    getPropostas,
    searchPropostas,
    getProposta,
    alterarProposta,
    deleteProposta,
    getPropostasIntervalo,

    enviarUrlServico,

    salvarCotacao,
    getCotacoes,
    getCotacoesProposta,

    getConfigVigenciaCotacao,

    sendCotacaoPDF,
  };
};

export default createApi();
