import React from 'react';

import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';

import logo from '../../assets/logo.png';

import {
  formatCnpjCpf
} from '~/utils/constants/formatCnpjCpf';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
    paddingTop: 10,
    paddingHorizontal: 25,
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 15,
  },

  title: {
  },

  filtros: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '100%',
    height: '70%',
    marginTop: 10
  },

  filtrosRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    height: '70%',
  },

  textFiltros: {
    fontSize: 10,
  },

  section: {
    marginTop: 20,
    width: '100%',
  },

  logo: {
    width: 70,
  },

  table: {
    display: 'flex',
    flexDirection: 'column',
  },

  tableRowHeader: {
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-around',
    width: '100%',
    borderBottom: .5,
  },

  tableRowHeaderProto: {
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: .5,
    backgroundColor: '#DDD'
  },

  tableLine: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderBottom: .3,
    borderBottomColor: '#CCC',
  },

  tableRow: {
    padding: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
  },

  tableCellHead: { fontSize: 11, width: '40%' },

  tableCellBody: { fontSize: 8, width: '60%' },

  tableCellBodyProto: { fontSize: 10, width: '60%', textAlign: 'right' },

  tableRowFooter: {
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'right',
    width: '100%',
  },
});

export default function DocumentPDF({ proposta }) {
  // const dataInicio = Intl.DateTimeFormat('pt-BR').format( filtros.start_date );
  // const dataFim = Intl.DateTimeFormat('pt-BR').format( filtros.end_date );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header }>
          <Text style={ styles.title }>Proposta</Text>

          <Image src={ logo } style={ styles.logo } />
        </View>

        <View style={ styles.table }>
          <View style={ styles.tableRowHeaderProto }>
            <Text style={ styles.tableCellHead } align="left">Protocolo:</Text>

            <Text style={ styles.tableCellBodyProto } align="left">{ proposta.pkProposta }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Imobiliária:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.nomeImobiliaria }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Tipo Proprietário:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.proprietarioTipo }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">CPF/CNPJ do Proprietário:</Text>

            <Text style={ styles.tableCellBody } align="left">{ formatCnpjCpf( proposta.proprietarioRegistro )}</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Nome do Proprietário:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.proprietarioNome }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Tipo Inquilino:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.inquilinoTipo }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">CPF/CNPJ do Inquilino:</Text>

            <Text style={ styles.tableCellBody } align="left">{ formatCnpjCpf( proposta.inquilinoRegistro )}</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Nome Inquilino:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.inquilinoNome }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Vigência - Início / Fim:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.vigenciaInicio } / { proposta.vigenciaFim }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">CEP:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.localCep }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Endereço:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.localEndereco }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Complemento:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.localComplemento }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Número:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.localNumero }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Bairro:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.localBairro }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Cidade/Estado:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.localCidade } - { proposta.localEstado }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Tipo de Imóvel:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.imovelTipoImovel }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Tipo de Ocupação:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.imovelTipoOcupacao }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Valor Aluguel:</Text>

            <Text style={ styles.tableCellBody } align="left">{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( proposta.valorAluguel )}</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Cobertura de Incêndio / Raio / Explosão:</Text>

            <Text style={ styles.tableCellBody } align="left">{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( proposta.valorSegurado )}</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Perda / Pagamento de Aluguel:</Text>

            <Text style={ styles.tableCellBody } align="left">{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( proposta.valorPagamentoAluguel )}</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Cobertura Responsabilidade Civil:</Text>

            <Text style={ styles.tableCellBody } align="left">{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( proposta.valorCoberturaResponsabilidadeCivil )}</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Cobertura Vendaval/Granizo:</Text>

            <Text style={ styles.tableCellBody } align="left">{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( proposta.valorCoberturaVendavalGranizo )}</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Cobertura Danos Elétricos:</Text>

            <Text style={ styles.tableCellBody } align="left">{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( proposta.valorCoberturaDanosEletricos )}</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Prêmio Líquido:</Text>

            <Text style={ styles.tableCellBody } align="left">{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( proposta.premioLiquido ) }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Prêmio Total:</Text>

            <Text style={ styles.tableCellBody } align="left">{ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( proposta.premioTotal )}</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Quantidade de Parcelas:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.qtdParcelas }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Assistência 24 horas:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.assistencia24HText }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Seguradora:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.nomeSeguradora }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Data da Proposta:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.dataProposta }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Situação:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.status }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Situação Pagamento:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.situacaoPagamento }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Situação Proposta:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.situacaoProposta }</Text>
          </View>

          <View style={ styles.tableRowHeader }>
            <Text style={ styles.tableCellHead } align="left">Número de Apólice:</Text>

            <Text style={ styles.tableCellBody } align="left">{ proposta.numeroProposta }</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
