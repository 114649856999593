import { object, string, number, ref } from 'yup';

import {
  validarCPF,
  validarCNPJ,
} from '~/utils/constants/validators';

export const SIGNUP_INITIAL = {
  nome: '',
  tipo: '',
  registro: '',
  cep: '',
  localidade: '',
  uf: '',
  email: '',
  nomeUsuario: '',
  senha: '',
  confirmarSenha: '',
  responsavelCreci: '',
  contatoNome: '',
  contatoFixo: '',
  contatoCelular: '',
};

export const signupSchema = object().shape({
  nome: string()
    .typeError('Nome deve ter formato texto')
    .required('Nome é obrigatório'),
  tipo: string()
    .typeError('Imobiliária deve ter formato texto')
    .required('Imobiliária é obrigatório'),
  registro: string()
    .typeError('CPF/CNPJ deve ter formato texto')
    .required('CPF/CNPJ é obrigatório')
    .min(11, 'Digite um CPF ou CNPJ válido')
    .max(14, 'Digite um CPF ou CNPJ válido')
    .test('PropValidationCPF_CNPJ', function ( value ) {
      const { path, createError, parent } = this;
      if( value ) {
        if( parent.tipo === 'PF' ) {
          if( !validarCPF( value )) {
            return createError({ path, message: 'CPF inválido' });
          }
        }

        if( parent.tipo === 'PJ' ) {
          if( !validarCNPJ( value )) {
            return createError({ path, message: 'CNPJ inválido' });
          }
        }
      }

      return true;
    }),
  cep: string().typeError('CEP deve ter formato texto'),
  localidade: string().typeError('Cidade deve ter formato texto'),
  uf: string()
    .typeError('Estado deve ter formato texto')
    .required('Estado é obrigatório'),
  email: string()
    .typeError('Email deve ter formato texto')
    .required('Email é obrigatório')
    .email('Deve ser um e-mail válido'),
  nomeUsuario: string()
    .typeError('Nome de usuário deve ter formato texto')
    .required('Nome de usuário é obrigatório'),
  senha: string()
    .typeError('Senha deve ter formato texto')
    .required('Senha é obrigatório'),
  confirmarSenha: string()
    .typeError('Confirmar senha deve ter formato texto')
    .required('Confirmar senha é obrigatório')
    .oneOf([ref('senha'), null], 'Senha não confere'),
  responsavelCreci: number()
    .typeError('Número CRECI deve ter formato numérico')
    .required('Número CRECI é obrigatório')
});
