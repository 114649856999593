import React, { createContext, useContext, useCallback } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';

import useAuth from './hooks/auth';
import useAlert from './hooks/alert';
import useUsers from './hooks/users';
import useContacts from './hooks/contacts';
import useBankData from './hooks/bankData';
import useImobiliaria from './hooks/imobiliaria';
import useSimulacao from './hooks/simulacao';
import usePropostas from './hooks/propostas';

export const store = createContext({
  auth: {},
  alert: {
    toasts: [],
    addToast: ({ message, type }) => {
      console.log({ message, type });
    },
    removeToast: id => {
      console.log(id);
    }
  },
  propostas: { data: [], getPropostas: () => ({ sucesso: true }), getPropostasIntervalo: () => ({ success: true })},
  imobiliaria: { data: [], fetching: false, getImobiliaria: () => {} },
  simulacao: {
    data: [],
    fetching: false,
    getSimulacao: async () => ({ success: true }),
    seguroSelected: 'codigo_seguro',
    selectSeguro: ({ seguro }) => {
      console.log({ seguro });
      return 'codigo_seguro';
    },
    confirmarSeguro: async ({ confirmFormData }) => {
      return { success: true };
    }
  }
});

const { Provider } = store;

export const useStore = () => useContext(store);

export function StateProvider({ children }) {
  const alert = useAlert();
  const auth = useAuth({ alert });

  const checkApiCall = useCallback(
    async (apiFunction, args = []) => {
      try {
        const response = await apiFunction(...args);

        return response;
      } catch (err) {
        if (err.response?.status === 401) {
          alert.addToast({ message: 'Sessão expirada' });
          auth.signout();
        }

        return err.response;
      }
    },
    [auth, alert]
  );

  const users = useUsers({ alert, checkApiCall });
  const contacts = useContacts({ alert, checkApiCall });
  const imobiliaria = useImobiliaria({ alert, auth, checkApiCall });
  const bankData = useBankData({ alert });
  const simulacao = useSimulacao();
  const propostas = usePropostas();

  return auth.mounted ? (
    <Provider
      value={{
        auth,
        alert,
        users,
        contacts,
        imobiliaria,
        bankData,
        simulacao,
        propostas
      }}
    >
      {children}
    </Provider>
  ) : (
    <Container
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={76} thickness={5} />
    </Container>
  );
}
