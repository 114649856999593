import { useState, useEffect, useMemo, useCallback } from 'react';

import { TOKEN_KEY, USUARIO_KEY, USER_KEY } from '~/utils/constants';
import api from '~/services/api';

export default function useAuth() {
  const [user, setUser] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [role, setRole] = useState(null);

  const signin = useCallback(async ({ email, password, remember }) => {
    setLoading(true);

    try {
      const { data } = await api.login({ username: email, password });

      setToken(data.token);
      localStorage.setItem(TOKEN_KEY, data.token);

      setUsuario(data.usuario);

      setRole(data.role);
      if (remember) {
        localStorage.setItem(USUARIO_KEY, data.usuario);
      } else {
        localStorage.removeItem(USUARIO_KEY);
      }

      setUser({ name: data.nome, role: data.role, imobiliaria: data.imobiliaria });
      localStorage.setItem(
        USER_KEY,
        JSON.stringify({ name: data.nome, role: data.role, imobiliaria: data.imobiliaria })
      );

      return { success: true };
    } catch( err ) {
      return { success: false, message: err.response.data };
    } finally {
      setLoading(false);
    }
  }, []);

  const signUp = useCallback(async ({ signUpData }) => {
    try {
      const { data } = await api.inserirImobiliaria({
        nome: signUpData.nome,
        tipo: signUpData.tipo,
        registro: signUpData.registro,
        endereco: signUpData.endereco,
        cep: signUpData.cep,
        pais: 'BR',
        estado: signUpData.uf,
        cidade: signUpData.localidade,
        responsavelCreci: signUpData.responsavelCreci,
        email: signUpData.email,
        senha: signUpData.senha,
        confirmarSenha: signUpData.confirmarSenha,
        nomeUsuario: signUpData.nomeUsuario,
        cargo: 'Administrador',
        role: 'AdmImobiliaria',
        notas: signUpData.notas,
        contatoNome: signUpData.contatoNome,
        contatoFixo: signUpData.contatoFixo,
        contatoCelular: signUpData.contatoCelular,
        vigencia: signUpData.vigencia,
      });

      // const { data: loginResult } = await api.login({
      //   username: signUpData.email,
      //   password: '1234'
      // });

      setToken(data.token);
      localStorage.setItem(TOKEN_KEY, data.token);

      setUser({ name: data.nome, role: data.role, imobiliaria: data.imobiliaria });
      localStorage.setItem(
        USER_KEY,
        JSON.stringify({ name: data.nome, role: data.role, imobiliaria: data.imobiliaria })
      );

      return { success: true };
    } catch( error ) {
      return { success: false, error };
    }
  }, []);

  async function signout() {
    setToken('');

    await api.logout();
    localStorage.removeItem(TOKEN_KEY);
  };

  useEffect(() => {
    if (token) {
      api.setAuthToken(token);
    } else {
      api.removeAuthToken();
    }
  }, [token]);

  useEffect(() => {
    const tokenStorage = localStorage.getItem(TOKEN_KEY);
    const usuarioStorage = localStorage.getItem(USUARIO_KEY);
    const userStorage = localStorage.getItem(USER_KEY);

    if (tokenStorage) {
      setToken(tokenStorage);
      api.setAuthToken(tokenStorage);
    }

    if (usuarioStorage) {
      setUsuario(usuarioStorage);
    }
    if (userStorage) {
      setUser(JSON.parse(userStorage));
    }

    setMounted(true);
  }, []);

  return useMemo(
    () => ({
      mounted,
      usuario,
      loading,
      user,
      token,
      signin,
      signUp,
      signout,
      role
    }),
    [mounted, usuario, loading, user, token, role, signin, signout, signUp]
  );
}
