import { makeStyles } from '@material-ui/core/styles'

const Styles = makeStyles(theme => ({
  root: {},
  card: {
    maxWidth: 240,
  },
  media: {
    height: 120,
    width: 120
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalBody: {},

  paper: {
    position: 'absolute',
    width: 480,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6
  },

  input: {
    width: '100%',
    marginTop: 15,
  },

  buttonView: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    marginTop: 20,
  },

  button: {
    marginRight: 15,
  }
}))

export default Styles
