import { tiposContato } from '~/utils/constants/contact';

import {
  formataNumeroTelefone,
} from '../constants/formatPhone';

export const transformContatos = ({ data }) =>
  data.map(dado => {
    return {
      ...dado,
      telefone: formataNumeroTelefone(dado.telefone.slice(0,2), dado.telefone.slice(2, 11)),
      celular: formataNumeroTelefone(dado.celular.slice(0,2), dado.celular.slice(2, 11)),
      id: dado.pkContatos,
      tipo: tiposContato.find(tipo => tipo.value === dado.tpContato).label
    };
  });
