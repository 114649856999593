import { makeStyles } from '@material-ui/core/styles'

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  title: {
    flex: '1 1 100%',
    color: theme.palette.primary.contrastText
  },
  addUser: {
    marginRight: 50,
  },

  icon: {
    color: theme.palette.primary.contrastText
  }
}))

export default useToolbarStyles
