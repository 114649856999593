import React from 'react'

import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import useStyles from './AddUser.styles'
import { AddUserForm } from './AddUserForm'

export function AddUserModal ({ open, close, selectedUser, getUsers }) {
  const classes = useStyles()

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby='adicionar-usuário'
      aria-describedby='adicionar-usuário'
    >
      <Paper className={classes.paper}>
        <Box className={classes.header}>
          <Typography variant='h5'>{ selectedUser ? 'Alterar' : 'Adicionar' } usuário</Typography>
          <IconButton
            className={classes.closeButton}
            onClick={close}
            edge='end'
            color='secondary'
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.formWrapper}>
          <AddUserForm
            selectedUser={ selectedUser }
            getUsers={ getUsers }
            close={ close }
          />
        </Box>
      </Paper>
    </Modal>
  )
}
