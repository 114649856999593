import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
  },
  fab: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: theme.spacing(15),
    right: theme.spacing(3),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginLeft: 5,
  },
  toolbar: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    minHeight: 40
  },
  title: {
    flex: '1 1 100%',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.fontSize * 1.25
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  input: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius
  },
  closeIcon: {
    color: theme.palette.common.white,
    fontWeight: 'bold'
  },
  close: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    zIndex: theme.zIndex.modal,
    right: 35,
    top: -18,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light
    }
  }
}));

export default Styles;
