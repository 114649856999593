import React, { useState, useEffect, useCallback } from 'react';

import Modal from '@material-ui/core/Modal';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';

import SendIcon from '@material-ui/icons/Send';

import { LoadingButton } from '~/components';

import api from '~/services/api';

import { Formik } from 'formik';

import { SignupForm } from '~/pages/PublicIncendio/Cadastro/SignupForm';

import { makeStyles } from '@material-ui/core/styles';
import { useStore } from '~/store';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    height: '80%',
    marginBottom: theme.spacing(8),
  },

  bodyModal: {
    overflowY: 'scroll',
  },

  button: {
    marginLeft: theme.spacing(1)
  },

  toolbar: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    minHeight: 40
  },

  title: {
    flex: '1 1 100%',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.fontSize * 1.25
  },

  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },

  appBar: {
    width: '100%',
  },

  tab: {
    width: '100%',
    padding: 20,
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 5,
  },

  formControlSs: {
    width: '15%',
    padding: 5,
  },

  formControlSm: {
    width: '25%',
    padding: 5,
  },

  formControlMd: {
    width: '50%',
    padding: 5,
  },

  formControlLg: {
    width: '100%',
    padding: 5,
  },

  formControlFull: {
    width: '100%',
    padding: 5,
  },

  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: theme.spacing(1)
  },
}));

function handleSetStatus( value ) {
  return value ? 'Ativo' : 'Inativo';
}

export default function ModalImobiliaria({ open, setOpen }) {
  const { alert } = useStore();
  const classes = useStyles();

  const [ fetching, setFetching ] = useState(false);

  async function handleSubmit(imobiliariaData) {
    setFetching(true);

    try {
      imobiliariaData.status = handleSetStatus(imobiliariaData.status);

      const { data } = await api.alterarImobiliaria( imobiliariaData );

      if( data.success ) {
        alert.addToast({
          type: 'success',
          message: 'Dados de Imobiliária alterado com sucesso!'
        });

        imobiliaria.getImobiliaria();

        setOpen(false);
      } else {
        alert.addToast({ message: 'Erro inesperado ao alterar dados' });
      }

      setFetching(false);
    } catch {
      alert.addToast({ message: 'Erro inesperado ao alterar dados' });

      setFetching(false);
    }
  };

  const { imobiliaria } = useStore();

  const [ imobiliariaData, setImobiliariaData ] = useState({});

  useEffect(() => {
    imobiliaria.getImobiliaria();
  }, []);

  useEffect(() => {
    if( imobiliaria.data ) {
      const objImobiliaria = {
        nome: imobiliaria.data.nome,
        tipo: imobiliaria.data.tipo,
        registro: imobiliaria.data.registro,
        cep: imobiliaria.data.cep,
        endereco: imobiliaria.data.endereco,
        complemento: imobiliaria.data.complemento,
        bairro: imobiliaria.data.bairro,
        localidade: imobiliaria.data.cidade,
        uf: imobiliaria.data.estado,
        email: imobiliaria.data.email,
        nomeUsuario: imobiliaria.data.nomeUsuario,
        senha: imobiliaria.data.senha,
        confirmarSenha: imobiliaria.data.confirmarSenha,
        responsavelCreci: imobiliaria.data.responsavelCreci,
        status: imobiliaria.data.status,
        contatoFixo: imobiliaria.data.contatoFixo,
        contatoCelular: imobiliaria.data.contatoCelular,
      };

      setImobiliariaData(objImobiliaria);
    }
  }, [ imobiliaria.data ]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen( false )}
      className={classes.modal}
    >
      <Paper className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6" id="usuarios">
            EDITAR IMOBILIÁRIA
          </Typography>
        </Toolbar>

        <div className={classes.bodyModal} style={{ padding: 10 }}>
          <Formik
            onSubmit={handleSubmit}
            initialValues={ imobiliariaData }
            validateOnMount
          >
            {formik => {
              return (
                <>
                  <SignupForm formik={formik} id={ imobiliaria.data.pkImobiliaria } />

                  <Box className={classes.buttonsWrapper}>
                    <LoadingButton
                      className={classes.button}
                      type="submit"
                      form="signup_form"
                      loading={fetching}
                      variant="contained"
                      color="primary"
                      endIcon={<SendIcon />}
                      size="large"
                    >
                      Salvar
                    </LoadingButton>

                    <LoadingButton
                      className={classes.button}
                      onClick={() => setOpen(false)}
                      variant="outlined"
                      color="primary"
                      size="large"
                    >
                      Cancelar
                    </LoadingButton>
                  </Box>
                </>
              )}
            }
          </Formik>
        </div>
      </Paper>
    </Modal>
  );
};
