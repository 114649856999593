export const getPropostasContent = ({ data }) =>
  data.map(dado => {
    return {
      ...dado,
      usuario: dado.usuarioNome,
      proprietario: dado.proprietario,
      inquilino: dado.inquilino,
      seguradora: dado.seguradoraNome,
      vigencia_start: dado.vigenciaInicio,
      vigencia_end: dado.vigenciaFim
    };
  });
