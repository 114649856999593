import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.6),
    paddingBottom: theme.spacing(2)
  },
  helperText: {
    position: 'absolute',
    bottom: -2,
    margin: '3px 0 0',
    fontSize: theme.typography.overline
  }
}));

export default Styles;
