import { object, string } from 'yup';

import {
  validarCPF,
  validarCNPJ,
} from '~/utils/constants/validators';

export const BANK_DATA_INITIAL = {
  tipoConta: '',
  agencia: '',
  conta: '',
  tipoPessoa: '',
  cpf: '',
  banco: ''
};

export const bankSchema = object().shape({
  tipoConta: string()
    .typeError('Tipo da conta deve ter formato texto')
    .required('Tipo da conta é obrigatório'),
  agencia: string()
    .typeError('Agência deve ter formato texto')
    .required('Agência é obrigatório'),
  conta: string()
    .typeError('Conta deve ter formato texto')
    .required('Conta é obrigatório'),
  tipoPessoa: string()
    .typeError('Tipo de pessoa deve ter formato texto')
    .required('Tipo de pessoa é obrigatório'),
  cpf: string()
    .typeError('CPF/CNPJ deve ter formato texto')
    .required('CPF/CNPJ é obrigatório')
    .test('validationCPF', function ( value ) {
      const { path, createError } = this;
      if( value ) {
        if( value.length <= 11 ) {
          if( !validarCPF( value )) {
            return createError({ path, message: 'CPF inválido' });
          }
        } else {
          if( !validarCNPJ( value )) {
            return createError({ path, message: 'CNPJ inválido' });
          }
        }
      }

      return true;
    }),
  banco: string()
    .typeError('Banco deve ter formato texto')
    .required('Banco é obrigatório')
});
