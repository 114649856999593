import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles(theme => ({
  price: {
    fontSize: theme.typography.fontSize * 1,
    color: theme.palette.text.primary
  },
  avatarCell: {
    textAlign: 'center'
  },
  avatar: {
    width: 100,
    height: 100,
    margin: 'auto'
  },
  coberturaHeader: {
    verticalAlign: 'bottom',
    fontSize: 20
  },
  cobertura: {
    whiteSpace: 'nowrap'
  },
  paperModal: {
    position: 'absolute',
    width: 480,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  button: {
    marginTop: 20,
  },

  root: {
    width: '70%',
  },

  toolbar: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    minHeight: 40
  },

  title: {
    flex: '1 1 100%',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.fontSize * 1.25
  },

  message: {
    fontSize: 18,
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 30,
  },

  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },

  bodyModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    height: '100%',
    padding: 10
  },
}));

export default Styles;
