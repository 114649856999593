import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Toolbar from '@material-ui/core/Toolbar';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import CircularProgress from '@material-ui/core/CircularProgress';
import Print from '@material-ui/icons/Print';

import {
  formatCnpjCpf
} from '~/utils/constants/formatCnpjCpf';

import {
  PDFDownloadLink,
} from '@react-pdf/renderer';

import MyDocument from '~/utils/template/proposta';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    // marginBottom: theme.spacing(8),
    overflow: 'hidden',
    width: '90%',
    height: '100%',
  },

  toolbar: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    minHeight: 40
  },

  title: {
    flex: '1 1 100%',
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.fontSize * 1.25
  },

  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },

  appBar: {
    width: '100%',
  },

  tab: {
    width: '100%',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  formControlSs: {
    width: '15%',
    padding: 5,
  },

  formControlSm: {
    width: '25%',
    padding: 5,
  },

  formControlMd: {
    width: '50%',
    padding: 5,
  },

  formControlLg: {
    width: '100%',
    padding: 5,
  },

  formControlFull: {
    width: '100%',
    padding: 5,
  },

  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ModalProposta({ data, setOpen, open }) {
  const classes = useStyles();

  const [ tab, setTab ] = useState(0);

  function handleChangeTab(event, newValue) {
    setTab( newValue );
  };

  function handleCloseModal() {
    setOpen( false );
    setTab( 0 );
  }

  useEffect(() => {
    if( data && data.dataProposta ) {
      const dataFormated = data.dataProposta.split('T')[0].split('-');
      data.dataProposta = `${dataFormated[2]}/${dataFormated[1]}/${dataFormated[0]}`;

      data.qtdParcelas = `${ data?.qtdParcelas } Parcela${ data?.qtdParcelas > 1 ? 's' : ''} de ${ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( data.premioTotal / data.qtdParcelas )}`;
    }
  }, [ data ]);

  return data && data.dataProposta ? (
    <Modal
      open={open}
      onClose={() => setOpen( false )}
      className={classes.modal}
    >
      <Paper className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6" id="usuarios">
            PROPOSTA
          </Typography>
        </Toolbar>

        <AppBar className={ classes.appBar } position="static">
          <Tabs variant="fullWidth" value={tab} onChange={handleChangeTab} >
            <Tab label="Dados Pessoais" />
            <Tab label="Endereço" />
            <Tab label="Valores" />
          </Tabs>
        </AppBar>

        <TabPanel className={ classes.tab } value={tab} index={0}>
          <div className={ classes.row }>
            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.pkProposta } size="small" label="Protocolo" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.dataProposta } size="small" label="Data da Proposta" variant="outlined" disabled/>
            </FormControl>
          </div>

          <div style={{ margin: 15 }}></div>

          <div className={ classes.row }>
            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.nomeImobiliaria } size="small" label="Imobiliária" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.nomeUsuario } size="small" label="Usuário" variant="outlined" disabled/>
            </FormControl>
          </div>

          <Typography style={{ margin: 15 }} variant="body1">
            Dados do Proprietário
          </Typography>

          <div className={ classes.row }>
            <FormControl className={ classes.formControlSs }>
              <TextField value={ data?.proprietarioTipo } size="small" label="Tipo" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlLg }>
              <TextField value={ data?.proprietarioNome } size="small" label="Nome do Proprietário" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlMd }>
              <TextField value={ formatCnpjCpf( data.proprietarioRegistro)} size="small" label="CPF/CNPJ do Proprietário" variant="outlined" disabled/>
            </FormControl>
          </div>

          <Typography style={{ margin: 15 }} variant="body1">
            Dados do Inquilino
          </Typography>

          <div className={ classes.row }>
            <FormControl className={ classes.formControlSs }>
              <TextField value={ data?.inquilinoTipo } size="small" label="Tipo Inquilino" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlLg }>
              <TextField value={ data?.inquilinoNome } size="small" label="Nome Inquilino" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlMd }>
              <TextField value={ formatCnpjCpf( data.inquilinoRegistro)} size="small" label="CPF/CNPJ do Inquilino" variant="outlined" disabled/>
            </FormControl>
          </div>

          <Typography style={{ margin: 15 }} variant="body1">
            Vigência
          </Typography>

          <FormControl className={ classes.formControlMd }>
            <TextField value={ data?.vigenciaInicio } size="small" label="Vigência - Início" variant="outlined" disabled/>
          </FormControl>

          <FormControl className={ classes.formControlMd }>
            <TextField value={ data?.vigenciaFim } size="small" label="Vigência - Fim" variant="outlined" disabled/>
          </FormControl>

          <div className={ classes.actions }>
            {
              data.arquivoSeguradora
                ? <Button
                    onClick={() => window.open(`${ process.env.REACT_APP_BASE_API_URL }/public/images/upload/formularios/${ data?.arquivoSeguradora }`)}
                    style={{ marginLeft: 30 }}
                    color="primary"
                  >
                    Download da Apólice
                  </Button>
                : <></>
            }

            {/* {
              data.urlBoleto ? (
                <Button
                  onClick={() => window.open( data.urlBoleto )}
                  style={{ marginLeft: 30 }}
                  variant="contained"
                  color="secondary"
                >
                  Emitir Boleto
                </Button>
              ) : (
                <div />
              )
            } */}

            <Button
              color="primary"
              style={{ marginLeft: 30 }}
              size="small"
            >
              <PDFDownloadLink document={<MyDocument proposta={ data } />} fileName="Relatorio.pdf">
                {({ blob, url, loading, error }) => ( loading ? <CircularProgress color="primary" size={ 20 } style={{ marginTop: 6 }}/> : <Print color="primary" size={ 10 } style={{ marginTop: 6 }}/>)}
              </PDFDownloadLink>
            </Button>

            <Button
              onClick={ handleCloseModal }
              style={{ marginLeft: 30 }}
              variant="contained"
              color="primary"
            >
              OK
            </Button>
          </div>
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <div className={ classes.row }>
            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.localCep } size="small" label="CEP" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.localCidade } size="small" label="Cidade" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.localEstado } size="small" label="Estado" variant="outlined" disabled/>
            </FormControl>
          </div>

          <div style={{ margin: 10 }} />

          <div className={ classes.row }>
            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.localEndereco } size="small" label="Endereço" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.localComplemento } size="small" label="Complemento" variant="outlined" disabled/>
            </FormControl>
          </div>

          <div style={{ margin: 10 }} />

          <div className={ classes.row }>
            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.localBairro } size="small" label="Bairro" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlSs }>
              <TextField value={ data?.localNumero } size="small" label="Número" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.imovelTipoImovel } size="small" label="Tipo de Imóvel" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.imovelTipoOcupacao } size="small" label="Tipo de Ocupação" variant="outlined" disabled/>
            </FormControl>
          </div>

          <div className={ classes.actions }>
            {/* {
              data.urlBoleto ? (
                <Button
                  onClick={() => window.open( data.urlBoleto )}
                  color="primary"
                  variant="contained"
                  color="secondary"
                >
                  Emitir Boleto
                </Button>
              ) : (
                <div />
              )
            } */}

            <Button
              color="primary"
              size="small"
            >
              <PDFDownloadLink document={<MyDocument proposta={ data } />} fileName="Relatorio.pdf">
                {({ blob, url, loading, error }) => ( loading ? <CircularProgress color="primary" size={ 20 } style={{ marginTop: 6 }}/> : <Print color="primary" size={ 10 } style={{ marginTop: 6 }}/>)}
              </PDFDownloadLink>
            </Button>

            <Button
              onClick={ handleCloseModal }
              variant="contained"
              color="primary"
            >
              OK
            </Button>
          </div>
        </TabPanel>

        <TabPanel value={tab} index={2}>
          <div className={ classes.row }>
            <FormControl className={ classes.formControlSm }>
              <TextField value={ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( data.valorAluguel )} size="small" label="Valor Aluguel" variant="outlined" disabled/>
            </FormControl>

            {/* Espaço Vago */}

            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.parametroQuantidadeAlugueis } size="small" label="Parâmetro de Quantidade de Alugueis" variant="outlined" disabled/>
            </FormControl>
          </div>

          <div style={{ margin: 10 }} />

          <div className={ classes.row }>
            <FormControl className={ classes.formControlMd }>
              <TextField value={ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( data.valorSegurado )} size="small" label="Cobertura de Incêndio / Raio / Explosão" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlMd }>
              <TextField value={ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( data.valorPagamentoAluguel )} size="small" label="Perda / Pagamento de Aluguel" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlMd }>
              <TextField value={ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( data.valorCoberturaResponsabilidadeCivil )} size="small" label="Cobertura Responsabilidade Civil" variant="outlined" disabled/>
            </FormControl>
          </div>

          <div style={{ margin: 10 }} />

          <div className={ classes.row }>
            <FormControl className={ classes.formControlSm }>
              <TextField value={ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( data.valorCoberturaVendavalGranizo )} size="small" label="Cobertura Vendaval/Granizo" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlSm }>
              <TextField value={ Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( data.valorCoberturaDanosEletricos )} size="small" label="Cobertura Danos Elétricos" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlSm }>
              <TextField value={Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format( data.premioTotal )} size="small" label="Prêmio Total" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlSm }>
              <TextField value={ data?.qtdParcelas } size="small" label="Quantidade Parcelas" variant="outlined" disabled />
            </FormControl>
          </div>

          <div style={{ margin: 10 }} />

          <div className={ classes.row }>
            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.assistencia24HText } size="small" label="Assistência 24 horas" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.nomeSeguradora } size="small" label="Seguradora" variant="outlined" disabled/>
            </FormControl>
          </div>

          <div style={{ margin: 10 }} />

          <div className={ classes.row }>
            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.situacaoPagamento } size="small" label="Situação Pagamento" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.situacaoProposta } size="small" label="Situação Proposta" variant="outlined" disabled/>
            </FormControl>
          </div>

          <div style={{ margin: 10 }} />

          <div className={ classes.row }>
            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.status } size="small" label="Status" variant="outlined" disabled/>
            </FormControl>

            <FormControl className={ classes.formControlMd }>
              <TextField value={ data?.numeroProposta } size="small" label="Número de Apólice" variant="outlined" disabled/>
            </FormControl>
          </div>

          <div className={ classes.actions }>
            {/* {
              data.urlBoleto ? (
                <Button
                  onClick={() => window.open( data.urlBoleto )}
                  color="primary"
                  variant="contained"
                  color="secondary"
                >
                  Emitir Boleto
                </Button>
              ) : (
                <div />
              )
            } */}

            <Button
              color="primary"
              size="small"
            >
              <PDFDownloadLink document={<MyDocument proposta={ data } />} fileName="Relatorio.pdf">
                {({ blob, url, loading, error }) => ( loading ? <CircularProgress color="primary" size={ 20 } style={{ marginTop: 6 }}/> : <Print color="primary" size={ 10 } style={{ marginTop: 6 }}/>)}
              </PDFDownloadLink>
            </Button>

            <Button
              onClick={ handleCloseModal }
              variant="contained"
              color="primary"
            >
              OK
            </Button>
          </div>
        </TabPanel>
      </Paper>
    </Modal>
  ) : <></>;
};
